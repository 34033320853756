<div class="page-content-wrapper d-flex flex-column">
  <div class="header d-flex">
    <span class="text-accent circle"></span>
  </div>
  <div class="main d-flex">
    <div class="left d-flex">
      <img src="../../../../../assets/img/page_not_found.png" class="align-self-center"/>
    </div>
    <div class="right align-self-center pr-5">
      <div class="item-container d-inline-flex flex-column">
        <div class="item text-accent-950 font-500 headline headline-font align-self-start">
          How did you get here ?!
        </div>
        <div class="item desc align-self-start">
          <p class="text-black description-font font-400 text-uppercase">The link you followed is probably broken or the page
            has been removed</p>
        </div>
        <div class="item contact align-self-start">
          <p class="text-primary let-us-know font-400">You think we made a mistake? <a class="text-accent-A600" href="mailto:info@step-up.today">Let us know about this!</a></p>
        </div>
        <div class="item action-container d-flex justify-content-end">
          <button class="font-400 back-to-home" mat-flat-button color="accent" (click)="backToHome()">
            Back to home
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer d-flex">
    <div class="item text-primary font-400 d-flex align-items-center"><i class="material-icons">copyright</i> <span>2021 Step-up. All rights reserved</span></div>
  </div>
</div>
