import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SmartyStreetsCheckResult } from '../interfaces';
import { ApiService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root'
})
export class SmartyStreetsService {

  constructor(private api: ApiService) { }

  checkUSAAddress(city: string, state: string, zipcode: string, street: string): Observable<SmartyStreetsCheckResult[]> {
    return this.api.get('utilities/address/validate', { params: { street, city, state, zipcode } })
      .pipe(map((response: any) => response.data));
  }
}
