import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { style, animate, transition, trigger } from '@angular/animations';

import { NavItem } from '../components/sidebar/nav-item.interface';
import { LayoutService, LayoutSettings, NotificationsPanel, TimelinePanel } from '../layout.service';

@Component({
  selector: 'app-ticketing-layout',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TicketingLayouComponent implements OnInit {

  navItems: NavItem[] = [];
  appsList = {
    active: [
      {
        name: 'Chorus',
        icon: 'erpLogo',
        url: 'https://erp.phillipscollection.com/'
      }
    ],
    future: []
  };

  isMenuCollapsed: boolean;
  
  timelinePanel: TimelinePanel;
  notificationsPanel: NotificationsPanel;

  constructor(private layoutService: LayoutService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon('taskManagerLogo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/task_manager_logo.svg'));

    this.layoutService.changes.subscribe((changes: Partial<LayoutSettings>) => {
      if ('timelinePanel' in changes) { this.timelinePanel = changes.timelinePanel; }
      if ('notificationsPanel' in changes) { this.notificationsPanel = changes.notificationsPanel; }
    });

    this.layoutService.setMainMenu({ collapsed: true, toggle: false });
  }
}
