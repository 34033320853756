import { Component, Input, OnInit } from '@angular/core';

import { CollectionSortControl } from '../collection.component';

@Component({
  selector: 'app-collection-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  providers: [{ provide: CollectionSortControl, useExisting: CollectionSortComponent }]
})
export class CollectionSortComponent implements OnInit, CollectionSortControl {

  @Input() arrowPosition: 'before' | 'after' = 'after';
  @Input() field: string;

  private onChange = (_: any) => {};

  loading: boolean;
  activeField: string;
  activeDirection: 'asc' | 'desc';

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  setSort(sorting: string | null) {
    if (sorting) {
      this.activeField = sorting.startsWith('-') ? sorting.slice(1) : sorting;
      this.activeDirection = sorting.startsWith('-') ? 'desc' : 'asc';
    } else {
      this.activeField = null;
      this.activeDirection = null;
    }
  }

  changeSort() {
  }

}
