import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { SearchComponent } from './search/search.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { MoreAppsComponent } from './more-apps/more-apps.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationsListComponent,
    SearchComponent,
    SidebarComponent,
    TimelineComponent,
    UserMenuComponent,
    MoreAppsComponent
],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    HttpClientModule
  ],
  exports: [
    HeaderComponent,
    NotificationsListComponent,
    SearchComponent,
    SidebarComponent,
    TimelineComponent,
    UserMenuComponent,
    MoreAppsComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
