import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAvailableInfo'
})
export class NotAvailableInfoPipe implements PipeTransform {

  transform(value: any): any {
    return value !== '' && value !== undefined && value !== null  ? value : 'N/A';
  }

}
