import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatFabMenu } from '@angular-material-extensions/fab-menu';

@Component({
  selector: 'app-list-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class ListFabComponent {

  @Input() icon = 'add';
  @Input() color: ThemePalette = 'accent';
  @Input() children: MatFabMenu[];

  @Output() fabClick: EventEmitter<string | number | void> = new EventEmitter();

  constructor() {}
}
