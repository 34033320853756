import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SavedSearchModel } from '../../models';
import { CollectionFilterDetails } from '../collection/filters/filters.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  @Input() filters: CollectionFilterDetails[];
  @Input() placeholder: string;
  @Input() route: string[];
  @Input() module: string;
  @Input() value: SavedSearchModel['params'];

  @Output() save: EventEmitter<SavedSearchModel> = new EventEmitter();

  get isEmpty(): boolean {
    return !this.value.search && !this.value.sort && JSON.stringify(this.value.filters) === '{}';
  }

  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    if (!this.value) { this.value = { search: '', filters: {}, sort: '' }; }
  }

  addFilter(filters: CollectionFilterDetails[]) {
    filters.forEach((filter) => this.value.filters[filter.name] = filter.values);
  }

  removeFilter(filters: CollectionFilterDetails[]) {
    filters.forEach((filter) => delete this.value.filters[filter.name]);
  }

  goToSearch() {
    const queryParams = new SavedSearchModel().hydrate({ params: this.value }).getQueryParams();
    this.router.navigate(this.route , { queryParams });
  }

  saveSearch() {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        header: 'Save Search',
        inputs: [
          { id: 'name', name: 'name', placeholder: 'Enter name here...', validators: [Validators.required] },
        ],
        buttons: [
          { text: 'Cancel', role: 'cancel', cssClass: 'bg-primary-100' },
          { text: 'Save', role: 'submit', color: 'accent', handler: (data) => {
              const search = new SavedSearchModel().hydrate({ module: this.module, name: data.name, params: this.value });
              this.save.emit(search);
            }
          }
        ]
      }
    });
  }
}
