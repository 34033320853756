import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

export interface ViewAs {
    type: string; // NUMBER | TEXT | BOOLEAN | CURRENCY | DATE | PHONE | EMAIL
    decimals?: number; // Default: 0
    currencyCode?: string; // Default: USD
    currencyDisplay?: string; // code | symbol / Defaults to: 'symbol'
    dateFormat?: string; // Default: MM/dd/YYYY
    link?: boolean; // Default: true
}

@Pipe({
    name: 'viewAs'
})
export class ViewAsPipe implements PipeTransform {

    constructor(private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

    transform(value: any, viewAs: ViewAs) {
        if (!viewAs) { return value; }

        if (viewAs.type === 'number') {
            return this.decimalPipe.transform(value, '1.0-' + viewAs.decimals);
        }
        if (viewAs.type === 'text' || viewAs.type === 'string') {
            return value.toString();
        }
        if (viewAs.type === 'boolean' || viewAs.type === 'bool') {
            return value === true ? 'Yes' : 'No';
        }
        if (viewAs.type === 'currency') {
            return this.currencyPipe.transform(value, viewAs.currencyCode, viewAs.currencyDisplay, '1.0-' + viewAs.decimals);
        }
        if (viewAs.type === 'date') {
            return this.datePipe.transform(value, viewAs.dateFormat);
        }

        // id no valid format is available return the raw value
        console.error('Invalid formating type used in pipe "viewAs"');
        return value;
    }

}
