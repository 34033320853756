<div [formGroup]="formGroup">
    <div class="row">
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Address</mat-label>
          <textarea matInput formControlName="address" class="address" cdkTextareaAutosize [rows]="6" (change)="onAddressChanged()" required></textarea>
          <app-form-control-error [control]="formGroup.get('address')" controlFriendlyName="Address" matSuffix></app-form-control-error>
        </mat-form-field>
      </div>
  
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country" (selectionChange)="clearStateInput(); onAddressChanged()" required>
            <mat-option *ngFor="let country of countries" [value]="country.iso3">{{ country.countryName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row city">
      <div class="col-md-18 offset-md-18 col-lg-18 offset-lg-18">
        <mat-form-field class="w-100">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" (change)="onAddressChanged()" required>
          <app-form-control-error [control]="formGroup.get('city')" controlFriendlyName="City" matSuffix></app-form-control-error>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row">
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Address Notes / SM</mat-label>
          <textarea matInput formControlName="notes" cdkTextareaAutosize class="notes"></textarea>
        </mat-form-field>
      </div>
  
      <div class="col">
        <div class="row">
          <div class="col-md-18 col-lg-18">
            <mat-form-field *ngIf="selectedCountry?.states" class="w-100">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" (selectionChange)="onAddressChanged()" required>
                <mat-option *ngFor="let state of (selectedCountry.states || [])" [value]="state.code">{{ state.code }}</mat-option>
              </mat-select>
              <app-form-control-error [control]="formGroup.get('state')" controlFriendlyName="State" matSuffix></app-form-control-error>
            </mat-form-field>
  
            <mat-form-field *ngIf="!selectedCountry?.states" class="w-100">
              <mat-label>State</mat-label>
              <input matInput formControlName="state" (change)="onAddressChanged()"/>
            </mat-form-field>
          </div>
          <div class="col-md-18 col-lg-18">
            <mat-form-field class="w-100">
              <mat-label>Zip</mat-label>
              <input matInput formControlName="zip" (change)="onAddressChanged()" required/>
              <app-form-control-error [control]="formGroup.get('zip')" controlFriendlyName="Zip" matSuffix></app-form-control-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row" *ngIf="!addressChecked && formGroup.get('isValid').value === 'invalid'">
      <div class="col">
        <div class="banner row align-items-center">
          <div class="symbol col-auto pl-0 pr-0 d-flex text-error">
            <mat-icon> warning </mat-icon>
          </div>
          <div class="message col">
            This address was not validated. Click <span class="verify-action" (click)="verifyAddress();"> here </span> to validate.
          </div>
        </div>
      </div>
    </div>
  
    <div class="row" *ngIf="addressChecked">
      <div class="col">
        <div class="banner error align-items-center" *ngIf="!suggestions.length">
          <div class="row align-items-center mx-0">
            <div class="symbol col-auto pl-0 pr-0 d-flex text-error">
              <mat-icon> warning </mat-icon>
            </div>
            <div class="message col">
              The address you entered could not be validated. Do you want to continue to use this address?
            </div>
          </div>
          <div class="row mx-0">
            <div class="col left-spaccing">
              <mat-checkbox (change)="onUserValidationChange($event)">
                I understand and I want to continue with this address
              </mat-checkbox>
            </div>
          </div>
        </div>
  
        <div class="banner" *ngIf="suggestions.length">
          <div class="row  align-items-center mx-0">
            <div class="symbol col-auto pl-0 pr-0 d-flex text-error">
              <mat-icon> warning </mat-icon>
            </div>
            <div class="message col">
               The address you entered matched following addresses. Please select your address from the list below: 
            </div>
          </div>
          <div class="row mx-0"> 
            <div class="col left-spaccing">
              <ul class="suggestions">
                <li *ngFor="let suggestion of suggestions">
                  <span class="address">
                    {{ suggestion.address }}, {{ suggestion.city }},
                    {{ suggestion.state }}
                    {{ suggestion.zip }}-{{ suggestion.plus4code }}
                  </span>
                  <span class="action">
                    <button mat-button color="accent" class="flex-fill" (click)="useSuggestion(suggestion)">Use this address</button>
                  </span>
                </li>
              </ul>
              <mat-checkbox (change)="onUserValidationChange($event)">I want to continue with entered address</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-10 pb-10" *ngIf="formGroup.get('rdi').value && formGroup.get('isValid').value === 'api_validated'">
      <div class="col left-spacing font-500">Address Type: {{ formGroup.get('rdi').value }}</div>
    </div>
  </div>
  