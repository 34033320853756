import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { InputConfig } from '../input-dynamic/input-dynamic.component';

@Component({
  selector: 'app-form-dynamic',
  templateUrl: './form-dynamic.component.html',
  styleUrls: ['./form-dynamic.component.scss']
})
export class FormDynamicComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() inputs: InputConfig[];

  constructor() { }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = new FormGroup({});
    }
  }

  addFormControl(name: string, control: FormControl) {
    setTimeout(() => this.formGroup.addControl(name, control));
  }
}
