import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core/services';
import { DocumentFileInfo } from '../models/document.model';

export interface UploadDocumentProps {
  name: string;
  type: string;
  content: string;
  privacy: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private api: ApiService) { }

  getDocumentInfo(documentId: string): Observable<DocumentFileInfo> {
    return this.api.get(`documents/${documentId}?alt=media`).pipe(
      map((response) => new DocumentFileInfo().hydrate(response.data))
    );
  }

  uploadDocument(props: UploadDocumentProps): Observable<DocumentFileInfo> {
    return this.api.post('documents', props).pipe(
      map(response => new DocumentFileInfo().hydrate(response.data))
    );
  }

  deleteDocument(id: string) {
    return this.api.delete(`documents/${id}`);
  }
}
