import { Pipe, PipeTransform } from '@angular/core';
import { GoogleLibPhoneNumberService } from '../services';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {
  constructor(
    private googleLibPhoneNumberService: GoogleLibPhoneNumberService,
  ) { }

  transform(value: string): string {
    try {
      const phoneNumberInstance = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(value);
      let country = '';
      try {
        country = this.googleLibPhoneNumberService.phoneNumberUtil.getRegionCodeForNumber(phoneNumberInstance)
      } catch (error) {
      }
      if (country === 'US') {
        return this.googleLibPhoneNumberService.phoneNumberUtil.format(phoneNumberInstance, this.googleLibPhoneNumberService.phoneNumberFormat.NATIONAL);
      } else {
        return this.googleLibPhoneNumberService.phoneNumberUtil.format(phoneNumberInstance, this.googleLibPhoneNumberService.phoneNumberFormat.INTERNATIONAL);
      }
    } catch (err) {
      return value;
    }
  }
}
