<div *ngIf="loading" class="w-100">
    <app-skelethon [skelethonConfig]="resultsListHeaderSkeleton" ></app-skelethon>
    <app-skelethon [skelethonConfig]="resultsListSkeleton"></app-skelethon>
</div>

<mat-table [hidden]="loading" [dataSource]="results">
    <!-- Define default header row -->
    <ng-container *ngIf="showHeader && headerRowDefs.length === 0">
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    </ng-container>

    <!-- Define default content row -->
    <ng-container *ngIf="contentRowDefs.length === 0">
        <mat-row *matRowDef="let entity; columns: columnsToDisplay; let i = index" [id]="entity._id"></mat-row>
    </ng-container>

    <!-- Define default footer row -->
    <ng-container *ngIf="showFooter && footerRowDefs.length === 0">
        <mat-footer-row *matFooterRowDef="columnsToDisplay"></mat-footer-row>
    </ng-container>

    <!-- Define default row for no results message -->
    <tr class="mat-row no-border" *matNoDataRow>
        <td class="mat-cell">No documents found</td>
    </tr>
</mat-table>