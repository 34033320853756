import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import { AccountService } from './modules/account/services/account.service';
import { ApiService, StorageService } from './core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [ style({  opacity: 0 }), animate('0.5s ease-out', style({  opacity: 1 })) ]),
        transition(':leave', [ style({ opacity: 1 }), animate('0.5s ease-in', style({ opacity: 0 })) ])
      ]
    )
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  appReady = false;

  private unAuthSubs: Subscription;
  private logoutSubs: Subscription;

  constructor(private accountService: AccountService, private router: Router, private storage: StorageService, private apiService: ApiService) { }

  ngOnInit() {
    this.logoutSubs = this.accountService.onLogout.subscribe(() => this.router.navigate(['account', 'login']));
    this.unAuthSubs = this.apiService.onUnauthorizedRequest.subscribe(() => this.accountService.logout());

    this.storage.preload().toPromise().then(() => {
      this.accountService.tryAutoLogin().then(() => {
        this.appReady = true;
        this.router.initialNavigation();
      });
    });
  }

  ngOnDestroy() {
    this.unAuthSubs.unsubscribe();
    this.logoutSubs.unsubscribe();
  }
}
