import { BaseModel } from 'src/app/core/models/base.model';

export class CreditCardModel extends BaseModel {
  id: string;
  creditCard: {
    number: string;
    holderName: string;
    expiration: {
      month: number;
      year: number;
    };
    cvv: number;
    type: string;
  };
}
