<div class="dialog-content-wrapper ">
  <div class="bg-primary-100 border-bottom rounded-top  border-primary-600 d-flex" mat-dialog-title>
    <div>
      <h2 class="fs-16 lh-18 text-black font-400 mb-0"> {{data.header}}</h2>
      <span class="d-block m-0 fs-14 lh-18 text-grey-1000 font-400">{{data.subHeader}}</span>
    </div>
    <button mat-icon-button tabindex="-1" class="close" (click)="onCancel()" *ngIf="!data.disableCloseBtn">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="position-relative">

    <ng-container *ngIf="isTemplateRef">
      <ng-container *ngTemplateOutlet="data.content"></ng-container>
    </ng-container>

    <ng-container *ngIf="!isTemplateRef">
      <p [innerHTML]="data.content"></p>
    </ng-container>

    <ng-container *ngIf="data.inputs">
      <form [formGroup]="form">
        <ng-container *ngFor="let input of data.inputs">
          <mat-form-field *ngIf="input.type === undefined || input.type === 'text'" class="w-100">
            <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder">
            <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'number'" class="w-100">
            <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder" type="number">
            <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'date'" class="w-100">
            <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder" [matDatepicker]="matDatePicker">
            <mat-datepicker-toggle matSuffix [for]="matDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #matDatePicker></mat-datepicker>
            <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
          </mat-form-field>
        </ng-container>
      </form>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="container">
      <div class="row">
        <ng-container *ngFor="let button of data.buttons">
          <button mat-flat-button [class]="button.cssClass" class="col" [color]="button.color"
            [disabled]="button.disabled" (click)="onButtonClick(button)">{{ button.text }}</button>
        </ng-container>
      </div>
    </div>
  </mat-dialog-actions>
</div>