import { Component, ElementRef, HostBinding, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipList } from '@angular/material/chips';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DocumentsService } from 'src/app/modules/documents/services/documents.service';
import { DocumentFileInfo } from 'src/app/modules/documents/models/document.model';

export interface FileUploadFile {
  id: string;
  name: string;
}

interface ReadFileOutput {
  name: string;
  type: string;
  content: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, ControlValueAccessor, Validators {
  static nextId = 0;

  @HostBinding() id = `autocomplete-chips-input-${FileUploadComponent.nextId++}`;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('filesChips') fileList: MatChipList;

  files: FileUploadFile[] = [];
  filesLoading: string[] = [];
  autocompleteChipsGroup: FormGroup;
  loading = false;
  onChangeFunc: (value: any) => void;
  onTouchedFunc: VoidFunction;
  private _ngControl;

  constructor(private snackBar: MatSnackBar, private fb: FormBuilder, @Optional() @Self() public ngControl: NgControl, private documentsService: DocumentsService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this._ngControl = ngControl;
    }
  }

  @Input() @Optional()
  get formControl() {
    return this._ngControl;
  }

  set formControl(formControl: FormControl) {
    if (formControl) {
      this._ngControl = formControl;
    }
  }

  private _privacy = 'public';

  get privacy() {
    return this._privacy;
  }

  @Input()
  set privacy(_privacy: string) {
    if (_privacy) {
      this._privacy = _privacy;
    }
  }

  private _value: FileUploadFile[] = [];

  get value() {
    return this._value;
  }

  @Input()
  set value(_value) {
    if (_value && _value.length > 0) {
      this._value = _value;
    }
  }

  ngOnInit() {
    if (this.value && this.value.length) {
      this.files = [].concat(this.value);
    }
    this.autocompleteChipsGroup = this.fb.group({
      inputControl: ['', []],
      filesChips: [this.files, [this.getValidators]]
    });
  }

  writeValue(files): void {
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChangeFunc = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouchedFunc = fn;
  }

  uploadButtonClick(event: MouseEvent) {
    event.preventDefault();

    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  uploadFile(event) {
    const files = [];

    for (const ev of event) {
      files.push(ev);
      this.filesLoading.push(ev.name);
    }

    const list = files.map((file) => this.readFile(file));

    forkJoin(list).subscribe((results: ReadFileOutput[]) => this.saveFiles(results));
  }

  readFile(file) {
    this.validateFilesLoading(true);
    return new Observable(obs => {
      if (!(file instanceof File)) {
        obs.error(new Error('`file` must be an instance of File.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = (e: any) => {
        const uint = new Uint8Array(e.target.result);

        const bytes = [];

        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });

        const readerResult = reader.result as string;

        return obs.next({
          name: file.name,
          type: file.type,
          content: readerResult.split(',')[1]
        });
      };
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(file);
    });
  }

  saveFiles(files: ReadFileOutput[]) {
    this.autocompleteChipsGroup.get('filesChips').setValue(files);
    this.autocompleteChipsGroup.get('inputControl').setValue('');
    forkJoin(
      files
        .map((file: ReadFileOutput) =>
          this.documentsService.uploadDocument({...file, privacy: this.privacy}))
    )
      .pipe(
        catchError(() => {
          this.snackBar.open('File could not be saved.', '', {duration: 3000});
          return of(null);
        })
      )
      .subscribe((filesSaved: DocumentFileInfo[]) => {
        this.filesLoading = [];
        if (filesSaved && filesSaved.length) {
          this.validateFilesLoading(false);
          filesSaved
            .forEach((file: DocumentFileInfo) => {
              this.files.push({
                id: file._id,
                name: file.fileName
              });
            });
          this.onChangeFunc(this.files);
        }
      });
  }

  removeFile(file: FileUploadFile) {
    this.validateFilesLoading(true);
    this.documentsService.deleteDocument(file.id).pipe(
      catchError(() => {
        this.snackBar.open('File could not be removed.', '', {duration: 3000});
        return of(null);
      })
    ).subscribe((removed) => {
      if (removed) {
        this.validateFilesLoading(false);
        this.files = this.files
          .filter((item: FileUploadFile) => item.id !== file.id);
        this.onChangeFunc(this.files);
        this.autocompleteChipsGroup.get('filesChips').setValue(this.files);
        this.autocompleteChipsGroup.get('inputControl').setValue('');
      }
    });
  }

  private getValidators(abstractControl: AbstractControl): ValidatorFn {
    if (abstractControl.validator) {
      return abstractControl.validator;
    }
    return null;
  }

  private validateFilesLoading(loading: boolean) {
    loading ? this._ngControl.control.setErrors({incorrect: true}) : this._ngControl.control.setErrors(null);
  }
}
