import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogButton, DialogData } from './dialog.interfaces';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  isTemplateRef = false;
  form: FormGroup = new FormGroup({});

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public fb: FormBuilder) {}

  ngOnInit() {
    if (this.data.content instanceof TemplateRef) {
      this.isTemplateRef = true;
    }

    if (this.data.inputs) {
      this.data.inputs.forEach((input) => {
        this.form.addControl(input.name, new FormControl(input.value  || '', input.validators || []));
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onButtonClick(button: DialogButton) {
    if (button.role === 'submit' && this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }

    const values = this.getValues();
    const handlerResponse = this.callButtonHandler(button, button.role === 'submit' ? values : undefined);

    if (button.role === 'cancel') { return this.dialogRef.close(); }
    if (handlerResponse !== false) {
      this.dialogRef.close({ values, ...handlerResponse as {} });
    }
  }

  private callButtonHandler(button: DialogButton, values: any) {
    if (button.handler) {
      return button.handler(values);
    }
    return {};
  }

  private getValues() {
    if (!this.data.inputs || this.data.inputs.length === 0) {
      return undefined;
    }
    return this.form.value;
  }
}
