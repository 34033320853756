<div class="row"
     [ngClass]="skelethonConfig.rowClass"
     *ngFor="let row of skelethonConfig.rows">
  <div class="col"
       [ngClass]="[column.class || '', skelethonConfig.columnClass || '']"
       *ngFor="let column of row.columns">
    <div class="ghost-placeholder">
      <div [ngClass]="column.type"></div>
    </div>
  </div>
</div>
