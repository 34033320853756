import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { AccountService } from 'src/app/modules/account/services/account.service';
import { SavedSearchModel } from '../../models';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.scss']
})
export class SavedSearchesComponent implements OnInit {

  @Input() module: string;
  @Input() route: string[];

  savedSearches: SavedSearchModel[] = [];
  loading: boolean;

  constructor(private router: Router, private snackBar: MatSnackBar, private accountService: AccountService) {}

  ngOnInit() {
    if (!this.module) { console.error(`Module input is required for '<app-saved-searches>' component`); return; }

    if (this.accountService.getApplicationData().savedSearches instanceof Array) {
      this.savedSearches = this.accountService.getApplicationData().savedSearches.map(el => new SavedSearchModel().hydrate(el));
    }
  }

  saveSearch(searchDetails: SavedSearchModel) {
    this.savedSearches.push(searchDetails);
    this.syncToUserData();
  }

  deleteSearch(savedSearch: SavedSearchModel) {
    this.savedSearches = this.savedSearches.filter((el: SavedSearchModel) => el !== savedSearch);
    this.syncToUserData();
  }

  goToSearch(savedSearch: SavedSearchModel) {
    const queryParams = savedSearch.getQueryParams();
    this.router.navigate(this.route , { queryParams });
  }

  filterByModule(savedSearch: SavedSearchModel, module: string) {
    return savedSearch.module === module;
  }

  private syncToUserData() {
    this.loading = true;

    const appData = this.accountService.getApplicationData();
    appData.savedSearches = this.savedSearches;
    this.accountService.saveApplicationData(appData)
      .catch(e => this.snackBar.open('There was an error. Please try again later.', null, { duration: 7000, panelClass: ['bg-danger', 'text-white'] }))
      .finally(() => this.loading = false);
  }
}
