<span class="action">
  <ng-container *ngIf="!children">
    <button (click)="fabClick.emit()" mat-fab [color]="color">
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="children">
    <mat-fab-menu [icon]="icon" [color]="color" [fabButtons]="children" (onFabMenuItemSelected)="fabClick.emit($event)"></mat-fab-menu>
  </ng-container>
</span>
