import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { CollectionSearchControl } from '../collection.component';

@Component({
  selector: 'app-collection-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [{ provide: CollectionSearchControl, useExisting: CollectionSearchComponent }]
})
export class CollectionSearchComponent implements OnInit, CollectionSearchControl {

  @ViewChild('input') inputElement: ElementRef;

  @Input() value: string = null;

  isOpen: boolean;
  loading: boolean;

  private onChange = (_: any) => {};

  constructor() { }

  ngOnInit(): void {
    if (this.value) { this.isOpen = true; }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  setSearch(value: string) {
    this.value = value;
    if (value) { this.isOpen = true; }
  }

  clear() {
    this.value = undefined;
    this.inputElement.nativeElement.focus();
    this.onChange(this.value);
  }

  open() {
    this.isOpen = true;
    setTimeout(() => this.inputElement.nativeElement.focus());
  }

  close() {
    this.isOpen = false;
  }

  search(value: string) {
    this.onChange(value);
  }

  onBlur() {
    if (!this.value) {
      this.close();
    }
  }

}
