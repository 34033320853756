<div class="file-upload"
     appDragDrop
     (filesDropped)="uploadFile($event)">

  <div class="row pt-2">
    <div class="col">
      <button mat-button
              class="browse"
              color="accent"
              (click)="uploadButtonClick($event)"> BROWSE
      </button>

      <span class="drag-drop-message"> or drag and drop files here</span>
    </div>
  </div>

  <div [formGroup]="autocompleteChipsGroup">
    <input type="file"
           #fileInput
           class="file-upload-hidden"
           (change)="uploadFile($event.target.files)">
    <div class="row">
      <div class="col mx-2">
        <div>
          <mat-chip-list #filesChips
                         aria-label="Files">
            <mat-chip *ngFor="let file of files"
                      class="default"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="removeFile(file)">
              {{file.name}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip *ngFor="let fileLoading of filesLoading"
                      class="default">
              {{fileLoading}}
              <mat-spinner color="accent"
                           class="initial"
                           diameter="20">
              </mat-spinner>
            </mat-chip>
            <app-form-control-error [control]="autocompleteChipsGroup.get('filesChips')"
                                    controlFriendlyName="Chips"
                                    matSuffix>
            </app-form-control-error>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>
