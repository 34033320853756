import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, filter, map, retryWhen, switchMap, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SseService {

    constructor(private http: HttpClient, private ngZone: NgZone) { }

    getEventListener(resource: string): Observable<any> {
        let lastEventId = null;

        return this.http.get(`${environment.apiUrl}auth/key`).pipe(
            switchMap((response: any) => this.getEventSource(resource, response.data.key, lastEventId)),
            tap((messageEvent: MessageEvent) => { lastEventId = messageEvent.lastEventId; }),
            map((messageEvent: MessageEvent) => JSON.parse(messageEvent.data)),
            retryWhen(error$ => {
                const retryError = error => {
                    return error instanceof Event && error.target instanceof EventSource;
                };

                return error$.pipe(
                    switchMap(error => retryError(error) ? of(error) : throwError(error)),
                    filter(error => retryError(error)),
                    delay(3000)
                );
            })
        );
    }

    private getEventSource(resource: string, authKey: string, lastEventId?: string) {
        return new Observable(observer => {
            let sourceUrl = `${environment.eventsServer}${resource}?authKey=${authKey}`;
            if (lastEventId) sourceUrl += '&LastEventId=' + lastEventId;

            const eventSource = new EventSource(sourceUrl);
            eventSource.onmessage = message => this.ngZone.run(() => observer.next(message));
            eventSource.onerror = error => {
                eventSource.close();
                this.ngZone.run(() => observer.error(error));
            };

            return () => {
                eventSource.close();
            }
        });
    }
}
