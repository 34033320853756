
<ng-container *ngIf="activeField === field">
    <ng-container *ngIf="arrowPosition === 'before'">
        <mat-icon>arrow_downward</mat-icon>
        <mat-icon>arrow_upward</mat-icon>
    </ng-container>
</ng-container>

<ng-content></ng-content>

<ng-container *ngIf="activeField === field">
    <ng-container *ngIf="arrowPosition === 'after'">
        <mat-icon>arrow_downward</mat-icon>
        <mat-icon>arrow_upward</mat-icon>
    </ng-container>
</ng-container>
