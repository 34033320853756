import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AsyncValidatorFn, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { path } from 'ramda';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiService } from 'src/app/core/services';
import { ViewAs } from '../../pipes/view-as.pipe';
import { DialogComponent } from '../dialog/dialog.component';

export interface InputConfig {
  type: string; // TEXT, TEXTAREA, SELECT, SEARCH, DATE, NUMBER, PHONE, EMAIL, TOGGLE
  multiselect?: boolean; // true or false in case type is SELECT
  multilevel?: boolean; // true or false in case type is SELECT
  name: string;
  label: string;
  default?: string | number | boolean | (string | number | boolean)[];
  placeholder?: string;
  validators?: {
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    pattern?: string; // regexp in js format
  };
  options?: {
    id?: string;
    parent?: string;
    label: string;
    value: string | number | boolean;
  }[];
  asyncOptions?: {
    url: string;
    properties: {
      asLabel: string; // name
      asValue: string // _id
      asId?: string // _id
      asParent?: string // parentId
    };
  };
  search?: {
    url: string; // customers?f=status eq ACTIVE&s=
    viewMode?: string; // options | dialog. Defaults to dialog
    row: {
      cels: {
        header?: string; // Customer Name
        property: string; // custname
        viewAs?: ViewAs;
      }[];
      asValue: string; // _id
    }
  };
  date?: {
    mode?: string; // EOD | BOD. Default: BOD
  };
}

interface InputInitEvent {
  control: FormControl;
}

@Component({
  selector: 'app-input-dynamic',
  templateUrl: './input-dynamic.component.html',
  styleUrls: ['./input-dynamic.component.scss'],
})
export class InputDynamicComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('inputConfig') config: InputConfig;

  @Output() init: EventEmitter<InputInitEvent> = new EventEmitter();

  formControl: FormControl;
  value: any;

  searching: boolean;
  searchResults: any[];
  selectedResult: any;
  private searchResultsDialog: MatDialogRef<DialogComponent>;
  @ViewChild('searchResultsTpl') searchResultsTemplate: TemplateRef<any>;

  constructor(private apiService: ApiService, private dialog: MatDialog) { }

  ngOnInit() {
    const formControl = new FormControl();

    if (this.config.default !== undefined) {
      formControl.setValue(this.config.default);
    }

    const validators = [];
    const asyncValidators = [];
    if (this.config.validators) {
      if (typeof this.config.validators.required === 'boolean') { validators.push(Validators.required); }
      if (typeof this.config.validators.pattern === 'string') { validators.push(Validators.pattern(this.config.validators.pattern)); }
      if (typeof this.config.validators.min === 'number') { validators.push(Validators.min(this.config.validators.min)); }
      if (typeof this.config.validators.max === 'number') { validators.push(Validators.max(this.config.validators.max)); }
      if (typeof this.config.validators.minLength === 'number') { validators.push(Validators.minLength(this.config.validators.minLength)); }
      if (typeof this.config.validators.maxLength === 'number') { validators.push(Validators.maxLength(this.config.validators.maxLength)); }
    }
    if (this.config.type === 'search') {
      asyncValidators.push(this.searchValidator());
    }
    formControl.setValidators(validators);
    formControl.setAsyncValidators(asyncValidators);

    this.formControl = formControl;
    this.init.emit({ control: formControl });
  }

  // ======= TYPE: DATE =======

  onDateChanged(value) {
    this.formControl.setValue(new Date(value).getTime() / 1000);
  }

  mapDatePickerValue(timestamp: number) {
    if (!timestamp) { return; }
    return new Date(timestamp * 1000);
  }

  // ======= TYPE: SEARCH =======

  onSearchSubmit() {
    this.searching = true;
    this.apiService.get(this.config.search.url.replace(/{{value}}/gi, this.formControl.value)).toPromise()
      .then(response => {
        this.searchResults = response.data;
        if (this.config.search.viewMode === 'dialog' || this.config.search.viewMode === undefined) {
          this.searchResultsDialog = this.dialog.open(DialogComponent, {
            disableClose: true,
            data: {
              header: `Select ${this.config.label}`,
              content: this.searchResultsTemplate
            },
          });
        }
      })
      .finally(() => this.searching = false);
  }

  extractProperty(data, property: string) {
    return path(property.split('.'), data);
  }

  mapColNames(cols: InputConfig['search']['row']['cels']) {
    return cols.map(cel => cel.property);
  }

  selectResult(result) {
    const value = this.extractProperty(result, this.config.search.row.asValue);
    this.selectedResult = result;
    this.formControl.setValue(value);
    this.searchResultsDialog.close();
  }

  resetSelectedResult() {
    this.selectedResult = null;
  }

  private searchValidator(): AsyncValidatorFn {
    return ((control: AbstractControl): ValidationErrors | null => {
      return this.selectedResult ? of(null).pipe(delay(10)) : of({ valueNotSelected: { value: null } }).pipe(delay(10));
    }).bind(this);
  }
}
