<div class="row py-4 px-4 m-0">
    <div class="col-30 align-self-center pl-0">
        <h2 class="text-accent-950 m-0">Notifications</h2>
    </div>
    <div class="col-6 align-self-center text-right pr-0">
        <span class="rounded-circle cursor-pointer close-btn d-flex justify-content-center align-items-center" (click)="close()">
            <i class="material-icons  text-grey-A300">close</i>
        </span>
    </div>
</div>
<div class="px-4 contet">
</div>