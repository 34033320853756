import { Directive, Input, TemplateRef, ElementRef, HostListener, ComponentRef, OnInit, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ToolTipComponent } from '../../shared/components/tool-tip/tool-tip.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[appToolTip]'
})
export class ToolTipDirective implements OnInit, OnDestroy {
  @Input() showToolTip = true;
  @Input() appToolTip = '';
  @Input() cssClass = '';
  @Input() content: TemplateRef<any>;

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }
 
    const positions = [
      new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' })
    ];

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(positions);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  @HostListener('mouseenter')
  show() {
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<ToolTipComponent> = this.overlayRef.attach(new ComponentPortal(ToolTipComponent));
      tooltipRef.instance.content = this.content;
      tooltipRef.instance.text = this.appToolTip;
      tooltipRef.instance.cssClass = this.cssClass;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
