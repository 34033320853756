<section class="stepper-wrapper">
    <header>
        <div  class="stepper-header">
            <span *ngFor="let step of steps; let i = index;  first as isFirst; last as isLast" class="step-header" (click)="step.select()"
            [ngClass]="{'has-error': step.hasError && selectedIndex !== i, 'is-completed':step.completed && selectedIndex !== i, 'last':isLast, 'first':isFirst}">
            <div class="cursor-pointer">
                <mat-icon *ngIf="selectedIndex === i" class="text-grey-1000">edit</mat-icon>
                <mat-icon *ngIf="step.hasError && selectedIndex !== i" class="text-danger">warning</mat-icon>
                <mat-icon *ngIf="step.completed && selectedIndex !== i" class="text-success">check_circle</mat-icon>
                <i class="material-icons-outlined" *ngIf="!step.hasError && !step.completed && selectedIndex !== i">{{step.icon}}</i>
                <div>
                    <span>Step {{i+1}}</span>
                    <p>{{ step.label }}</p>
                </div>
            </div>
        </span>
        </div>
    </header>
    <div class="stepper-content-wrapper">
        <div class="stepper-content">
            <ng-container [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>
        </div>
    </div>
</section>