import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutService, LayoutSettings, SearchInput } from 'src/app/layout/layout.service';
import { CollectionSearchControl } from 'src/app/shared/components/collection/collection.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [{ provide: CollectionSearchControl, useExisting: SearchComponent }]
})
export class SearchComponent implements OnInit, OnDestroy, CollectionSearchControl {

  @Input() value: string;

  @ViewChild('input') inputElement: ElementRef;

  loading: boolean;
  config: SearchInput;

  private onChange = (_: any) => {};
  private changesSubscription: Subscription;

  constructor(private layoutService: LayoutService) {
    this.layoutService.searchComponent = this;
  }

  ngOnInit() {
    this.changesSubscription = this.layoutService.changes.subscribe((changes: Partial<LayoutSettings>) => {
      if ('searchInput' in changes) { 
        this.config = changes.searchInput; 
        this.value = changes.searchInput?.value ? changes.searchInput.value : '';
      }
    });
  }

  ngOnDestroy() {
    this.changesSubscription.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  setSearch(value: string) {
    this.value = value;
  }

  submit(value: string) {
    this.onChange(value);
    
    if (this.config.onSubmit) {
      this.config.onSubmit(value);
    }
  }

  changed(value: string) {
    if (this.config.onChange) {
      this.config.onChange(value);
    }
  }

  clear() {
    this.value = undefined;
    this.inputElement.nativeElement.focus();
    this.onChange(this.value);

    if (this.config.onClear) {
      this.config.onClear();
    }
  }
}
