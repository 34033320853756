import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { CollectionPaginatorControl } from '../collection.component';

export interface PaginationChangeEvent {
  page: number;
  limit: number;
  previousPage: number;
  previousLimit: number;
}

@Component({
  selector: 'app-collection-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  providers: [{ provide: CollectionPaginatorControl, useExisting: CollectionPaginatorComponent }]
})
export class CollectionPaginatorComponent implements OnInit, CollectionPaginatorControl {

  @Input() limitOptions: number[];
  @Input() disabled: boolean;

  @Output('change') changeEvent: EventEmitter<PaginationChangeEvent> = new EventEmitter();

  page: number;
  limit: number;
  total: number;
  loading: boolean;
  
  private previousPage: number;
  private previousLimit: number;
  private onChange = (_: any) => {};

  constructor() { }

  ngOnInit(): void { }

  handlePageEvent(event: PageEvent) {
    this.previousPage = this.page;
    this.previousLimit = this.limit;
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;

    const pagination = { page: this.page, limit: this.limit, previousPage: this.previousPage, previousLimit: this.previousLimit };
    this.changeEvent.emit(pagination);
    this.onChange(pagination);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  setPage(value: number) {
    this.previousPage = this.page;
    this.page = value;
  }

  setLimit(value: number) {
    this.previousLimit = this.limit;
    this.limit = value;
  }

  setTotal(total: number) {
    this.total = total;
  }
}
