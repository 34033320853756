import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

import { ItemModel } from '../../modules/inventory/models';
import { environment } from '../../../environments/environment';

@Directive({
  exportAs: 'itemImage',
  selector: 'img[item]'
})
export class ItemImageDirective implements OnInit {

  @Input() item: ItemModel | string;
  @Input() width: number;

  imgSize: string;
  sizes: string[] = ['tn', 'sm', 'md', 'lg'];
  placeholder = new Image();

  private loadingSrc = 'assets/img/loading/spinner.svg';
  private defaultSrc = 'assets/img/placeholders/';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    switch (true) {
      case (this.width <= 245):
        this.imgSize = 'tn';
        break;
      case (this.width >= 246 && this.width <= 500):
        this.imgSize = '';
        break;
      case (this.width >= 501 && this.width <= 1000):
        this.imgSize = 'md';
        break;
      case (this.width >= 1001):
        this.imgSize = 'lg';
        break;
      default:
        this.imgSize = '';
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', this.loadingSrc);
    this.setSrc();
  }

  setSrc() {
    let imgUrl;
    let srcSet;

    if ( typeof this.item === 'string' ) {
      imgUrl = environment.imageUrl + this.item as string;
      const srcSetUrl = environment.imageUrl + this.item as string + '/';
      srcSet = this.setSrcSet(srcSetUrl);
    } else {
      const _item = this.item as ItemModel;
      const hasImages = _item && _item.assets && _item.assets.images;
      const mainImage = hasImages && _item.assets.images.main;
      const firstDetailsImage = hasImages && _item.assets.images.details && _item.assets.images.details[0];
      const firstLifeStyleImage = hasImages && _item.assets.images.lifestyle && _item.assets.images.lifestyle[0];
      const src = mainImage || firstDetailsImage || firstLifeStyleImage;

      if (src) {
        imgUrl = src;
        srcSet = this.setSrcSet(src);
      }
    }

    this.placeholder.setAttribute('src', imgUrl);
    this.placeholder.onload = () => {
      this.renderer.setAttribute(this.el.nativeElement, 'src', imgUrl);
      if (srcSet) {
        this.renderer.setAttribute(this.el.nativeElement, 'srcset', srcSet.replace(/,\s*$/, ''));
      }
    };
    this.placeholder.onerror = () => {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultSrc + this.imgSize + '.jpg');
    };
  }

  setSrcSet(src) {
    let srcSet = '';
    const slices = this.sizes.slice(this.sizes.indexOf(this.imgSize));
    slices.forEach((slice, index) => {
      index++;
      srcSet += src  + ' ' + index + 'x, ';
    });
    return src;
  }
}
