import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CollectionFilter, CollectionFiltersControl } from '../collection.component';

export interface CollectionTabDetails {
  name: string;
  label: string;
  query: string;
  matIcon?: string;
  svgIcon?: string;
  count?: number;
  color?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-collection-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  providers: [{ provide: CollectionFiltersControl, useExisting: CollectionTabsComponent }]
})
export class CollectionTabsComponent implements OnInit, CollectionFiltersControl {

  @Input() tabs: CollectionTabDetails[] = [];

  @Output() change: EventEmitter<CollectionTabDetails> = new EventEmitter();

  selected: CollectionTabDetails;
  loading: boolean;

  private onChange = (_: any) => {};

  constructor() { }

  ngOnInit(): void { }

  // === Functions required for CollectionFiltersControl ===

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnAdd(fn: any): void { }
  registerOnRemove(fn: any): void { }

  getFilters() {
    return [{ name: '_tab', value: this.selected.name, query: this.selected.query }];
  }

  setValues(values: CollectionFilter[]): void {
    const found = values.find(el => el.name === '_tab');
    if (found) {
      const tab = this.tabs.find(t => t.name === found.value);
      if (tab) { this.selected = tab }
    } else {
      this.selected = this.tabs[0];
    }
  }

  // === Functions to interact with the component ===

  changeTab(tab: CollectionTabDetails) {
    this.selected = tab;
    
    this.change.emit(tab);
    this.onChange([{ name: '_tab', value: this.selected.name, query: this.selected.query }]);
  }
}
