import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DocumentFileInfo } from 'src/app/modules/documents/models/document.model';
import { DocumentsService } from 'src/app/modules/documents/services/documents.service';

export interface FileReference {
  id: string;
  name: string;
}

interface ReadFileOutput {
  name: string;
  type: string;
  content: string;
}

@Component({
  selector: 'app-input-files',
  templateUrl: './input-files.component.html',
  styleUrls: ['./input-files.component.scss']
})
export class InputFilesComponent implements OnInit, ControlValueAccessor {

  @Input() privacy = 'public';

  @Input() set value(val: FileReference[]) {
    this.filesReady = val;
  }
  get value() {
    return this.filesReady;
  }

  loading: boolean;
  disabled: boolean;

  filesReady: FileReference[] = [];
  filesLoading: string[] = [];

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private documentsService: DocumentsService, private snackBar: MatSnackBar, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void { }

  writeValue(value: any) {
    this.filesReady = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  removeFile(file: FileReference) {
    this.filesReady = this.filesReady.filter((item: FileReference) => item.id !== file.id);
    this.onChange(this.filesReady);
  }

  readAndUploadFiles(event) {
    if (this.disabled) { return; }

    const files = [];

    for (const ev of event) {
      files.push(ev);
      this.filesLoading.push(ev.name);
    }

    const list = files.map((file) => this.readFile(file));

    forkJoin(list).subscribe((results: ReadFileOutput[]) => this.saveFiles(results));
  }

  private readFile(file) {
    this.loading = true;

    return new Observable(obs => {
      if (!(file instanceof File)) {
        obs.error(new Error('`file` must be an instance of File.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = (e: any) => {
        const uint = new Uint8Array(e.target.result);

        const bytes = [];

        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });

        const readerResult = reader.result as string;

        return obs.next({
          name: file.name,
          type: file.type,
          content: readerResult.split(',')[1]
        });
      };
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(file);
    });
  }

  saveFiles(files: ReadFileOutput[]) {
    forkJoin(
      files.map((file: ReadFileOutput) => this.documentsService.uploadDocument({...file, privacy: this.privacy}))
    ).pipe(
      catchError(() => {
        this.snackBar.open('File could not be saved.', null, { duration: 3000, panelClass: ['bg-danger', 'text-white'] });
        return of(null);
      })
    ).subscribe((filesSaved: DocumentFileInfo[]) => {
      this.filesLoading = [];
      if (filesSaved && filesSaved.length) {
        this.loading = false;
        filesSaved.forEach((file: DocumentFileInfo) => {
            this.filesReady.push({
              id: file._id,
              name: file.fileName
            });
          });
        this.onChange(this.filesReady);
      }
    });
  }
}
