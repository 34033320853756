<mat-chip-list>
  <mat-chip (click)="open()" *ngIf="available.length > 0" class="text-primary-800 border-primary-800 bg-white add-filters-button">
    <mat-icon matChipAvatar>add</mat-icon>
    Add a filter
  </mat-chip>

  <mat-chip *ngFor="let filter of applied" (click)="$event.stopPropagation()" (removed)="remove(filter)" class="applied-filter-chip">
    {{ filter.name }}: {{ prettyValues[filter.id] }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>

<span class="text-uppercase filters-reset align-self-center pl-4 text-primary-A100" (click)="resetAll()" *ngIf="applied.length > 0">
  RESET ALL FILTERS
</span>

<mat-card *ngIf="isOpen | async" (click)="clickInside()" [ngClass]="{'large': selected?.options?.length > 4, 'filters-list': !selected}" class="p-0">

  <mat-action-list dense *ngIf="!selected">
    <ng-container *ngFor="let filter of available; last as isLast">
      <mat-list-item (click)="select(filter)">
        <h4 mat-line>{{ filter.name }}</h4>
      </mat-list-item>
      <mat-divider *ngIf="!isLast"></mat-divider>
    </ng-container>
  </mat-action-list>

  <ng-container *ngIf="selected">
    <mat-card-header class="bg-primary-100 border-bottom border-primary-600 rounded-top m-0 p-2 align-items-center">
      <mat-card-title class="m-0">{{ selected.name }}</mat-card-title>
      <button mat-icon-button (click)="clickOut()" class="ml-auto">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-card-header>

    <mat-card-content class="p-4 m-0">
      <ng-container *ngIf="selected.searchEnabled && [TYPE_RADIO, TYPE_MULTI_SELECT].includes(selected.type)">
        <input matInput class="mb-3 filters-search" type="text" placeholder="Search..." (input)="setSearchTerm($event.target.value)">
      </ng-container>

      <ng-container *ngIf="selected.type === TYPE_INPUT">
        <mat-form-field>
          <ng-container *ngIf="selected.input?.type === 'date'; else basicInput">
            <input matInput [matDatepicker]="inputDatePicker" placeholder="{{ selected.input?.placeholder }}" (focus)="inputDatePicker.open()" (dateInput)="setFilterValue(selected, $event.value)">
            <mat-datepicker-toggle matSuffix [for]="inputDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #inputDatePicker (opened)="openOverlay()" (closed)="closedOverlay()"></mat-datepicker>
          </ng-container>

          <ng-template #basicInput>
            <input matInput type="{{ selected.input?.type ? selected.input?.type : 'text' }}" placeholder="{{ selected.input?.placeholder }}"
                   (change)="setFilterValue(selected, $event.target.value)">
            <span *ngIf="selected.input?.hint">E.g. {{ selected.input?.hint }}</span>
          </ng-template>
        </mat-form-field>
      </ng-container>
      
      <ng-container *ngIf="selected.type === TYPE_RADIO">
        <mat-radio-group (change)="setFilterValue(selected, $event.value)">
          <mat-radio-button *ngFor="let option of selected.options | optionsFilter: searchTerm" [value]="option.value" class="d-block">
            {{ option.label }}
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>

      <ng-container *ngIf="selected.type === TYPE_MULTI_SELECT">
        <mat-checkbox *ngFor="let option of selected.options | optionsFilter: searchTerm" class="d-block" [checked]="selected.values.includes(option.value)"
                      (click)="toggleValue(selected, option.value, $event)">{{ option.label }}</mat-checkbox>
      </ng-container>

      <ng-container *ngIf="selected.type === TYPE_RANGE">
        <ng-container *ngIf="selected.range?.type === 'date'">
          <mat-form-field>
            <input matInput [matDatepicker]="fromDatePicker" placeholder="from" (focus)="fromDatePicker.open()" (dateInput)="setFilterValue(selected, $event.value, 0)">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker (opened)="openOverlay()" (closed)="closedOverlay()"></mat-datepicker>
          </mat-form-field>
          <span> - </span>
          <mat-form-field>
            <input matInput [matDatepicker]="toDatePicker" placeholder="to" (focus)="toDatePicker.open()" (dateInput)="endOfDay($event) && setFilterValue(selected, $event.value, 1)">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker (opened)="openOverlay()" (closed)="closedOverlay()"></mat-datepicker>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selected.range?.type === 'number'">
          <mat-form-field>
            <input matInput type="number" placeholder="from" (change)="setFilterValue(selected, $event.target.value, 0)">
          </mat-form-field>
          <span> - </span>
          <mat-form-field>
            <input matInput type="number" placeholder="to" (change)="setFilterValue(selected, $event.target.value, 1)">
          </mat-form-field>
        </ng-container>
      </ng-container>
    </mat-card-content>

    <mat-card-footer class="border-top  border-primary-600 p-0 m-0 text-center">
      <button mat-button class="w-100 bg-primary-100 rounded-0" (click)="apply(selected)">Apply</button>
    </mat-card-footer>

  </ng-container>
</mat-card>
