<div class="file-upload" appDragDrop (filesDropped)="readAndUploadFiles($event)">
    <div class="row pt-2">
        <div class="col">
            <button mat-button color="accent" (click)="fileInput.click()">BROWSE</button>
            <span class="drag-drop-message"> or drag and drop files here</span>
        </div>
    </div>

    <div>
        <input type="file" #fileInput class="file-upload-hidden" (change)="readAndUploadFiles($event.target.files)" [disabled]="disabled">
        <div class="row">
            <div class="col mx-2">
                <div>
                    <mat-chip-list aria-label="Files" [selectable]="false">
                        <mat-chip *ngFor="let file of filesReady" [removable]="true" (removed)="removeFile(file)" [disabled]="disabled">
                            {{ file.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip *ngFor="let fileLoading of filesLoading" [disabled]="disabled">
                            <span class="mr-10">{{ fileLoading }}</span>
                            <mat-spinner color="accent" class="initial" diameter="20"></mat-spinner>
                        </mat-chip>
                        <app-form-control-error [control]="ngControl" controlFriendlyName="Files"></app-form-control-error>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </div>
</div>