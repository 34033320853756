import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Directive({
    selector: '[ifAllowed]'
})
export class IfAllowedDirective {

    @Input() set ifAllowed(permissions: string | string[]) {
        this.authService.isAllowed(permissions) ? this.container.createEmbeddedView(this.templateRef) : this.container.clear();
    }

    constructor(private templateRef: TemplateRef<any>, private container: ViewContainerRef, private authService: AuthService) { }
}
