import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter',
  pure: false
})
export class ArrayFilterPipe implements PipeTransform {

  transform(items: any[], filterFn: (item: any, ...args: any[]) => boolean, ...args: any[]): any {
    if (!items || !filterFn) { return items; }

    return items.filter(item => filterFn(item, ...args));
  }
}
