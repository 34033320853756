<div class="available-apps-wrapper" (click)="onCancel()">
    <div class="d-flex available-apps" (click)="$event.stopPropagation()">
        <div class="logo-wrapper align-self-strech d-flex" >
            <div class="alt-logo mt-4 mb-4 text-center">
                <mat-icon [svgIcon]="appLogo"  class="align-self-center mx-auto"  color="accent"></mat-icon>
              </div>
              <div class="text-center more-apps">
                <span class="text-center rounded-circle d-flex all-apps justify-content-center m-auto" (click)="onCancel()"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">apps</i></span>
              </div>
        </div>
        <div class="apps-wrapper align-self-strech">
            <div class="row mt-4 mb-3 mx-0 w-100" *ngIf="apps.active.length">
                <div class="col-30 title text-grey-A300 font-weight-medium text-uppercase pl-0 align-self-center">Applications</div>
                <div class="col-6 align-self-center">
                    <span class="rounded-circle cursor-pointer close-btn d-flex justify-content-center align-items-center" (click)="onCancel()">
                        <i class="material-icons  text-grey-A300">close</i>
                    </span>
                </div>
            </div>
            <div class="apps-list" *ngIf="apps.active.length">
                <a *ngFor="let app of apps.active" [href]="app.url" target="_blank" class="d-flex   mb-2">
                    <mat-icon [svgIcon]="app.icon" color="default" class="align-self-center mr-3"></mat-icon>
                    <span class="align-self-center ">{{app.name}}</span>
                </a>
            </div>
       
            <div class="mt-4 mb-3 mx-0 w-100 pt-2" *ngIf="apps.future.length">
                <div class="title text-grey-A300 font-weight-medium text-uppercase pl-0">More Products</div>
            </div>
            <div class="apps-list">
                <a *ngFor="let app of apps.future" [href]="app.url" target="_blank" class="d-flex mb-2">
                    <mat-icon [svgIcon]="app.icon"  class="align-self-center  mr-3"  color="accent"></mat-icon>
                    <span class="align-self-center ">{{app.name}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
