import { mergeDeepRight, isNil, pick } from 'ramda';

export abstract class BaseModel {

  hydrate(value: { [key: string]: any }): this {
    const merged = mergeDeepRight(this, value);
    Object.assign(this, merged);
    return this;
  }

  extract(properties?: string[]) {
    if (isNil(properties)) {
      return JSON.parse(JSON.stringify(Object.assign({}, this)));
    } else {
      return pick(properties, this);
    }
  }
}
