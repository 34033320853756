import { Component, Input, OnDestroy, Optional, Self, ElementRef, DoCheck } from '@angular/core';
import { ControlValueAccessor, NgControl, FormControl, FormGroup } from '@angular/forms';
import { GoogleLibPhoneNumberService } from '../../services';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputComponent }],
  host: {
    '[id]': 'id',
  }
})
export class PhoneInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, DoCheck {
  static nextId = 0;

  focused = false;
  errorState = false;
  stateChanges = new Subject<void>();
  describedBy = '';
  id = `phone-input-${PhoneInputComponent.nextId++}`;

  formGroup = new FormGroup({
    phone: new FormControl('')
  });

  constructor(
    private googleLibPhoneNumberService: GoogleLibPhoneNumberService,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    focusMonitor
      .monitor(elementRef, true)
      .subscribe(origin => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty() {
    return !this.formGroup.get('phone').value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this.inputPlaceholder;
  }

  set placeholder(value: string) {
    this.inputPlaceholder = value;
    this.stateChanges.next();
  }
  private inputPlaceholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string {
    return this.formGroup.get('phone').value;
  }

  set value(value: string) {
    this.formGroup.get('phone').setValue(this.getPhoneNumberFormattedForDisplay(value));
    this.stateChanges.next();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  onTouched: any = () => { };

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  registerOnChange(onChange: any): void {
    this.formGroup.get('phone').valueChanges
      .subscribe((phoneNumber: string) => {
        onChange(this.getPhoneNumberFormattedForUse(phoneNumber));
      });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    this.formGroup.get('phone').setValue(this.getPhoneNumberFormattedForDisplay(value), { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onBlur(ev) {
    this.onTouched();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  getPhoneNumberFormattedForDisplay(phoneNumber: string): string {
    try {
      const phoneNumberInstance = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
      return this.googleLibPhoneNumberService.phoneNumberUtil.formatOutOfCountryCallingNumber(phoneNumberInstance);
    } catch (err) {
      return phoneNumber;
    }
  }

  getPhoneNumberFormattedForUse(phoneNumber: string): string {
    try {
      const phoneNumberInstance = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
      return this.googleLibPhoneNumberService.phoneNumberUtil.format(phoneNumberInstance, this.googleLibPhoneNumberService.phoneNumberFormat.E164);
    } catch (err) {
      return phoneNumber;
    }
  }
}
