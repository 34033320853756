import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, tap, map, filter, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

    private isRefreshing: boolean;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private skipToken = [
        'oauth',
        'status'
    ];

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith(environment.apiUrl)) {
            return this.applyLogic(request).pipe(
                switchMap((newRequest: HttpRequest<any>) => next.handle(newRequest))
            );
        } else {
            return next.handle(request);
        }
    }

    private applyLogic(request: HttpRequest<any>) {
        request = this.appendApiKey(request);

        if (this.tokenNotRequired(request)) {
            return of(request);
        }

        if (this.isRefreshing) {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                map(() => this.appendToken(request))
            );
        }

        if (this.authService.isTokenExpired()) {
            this.isRefreshing = true;
            return this.authService.refreshTokens().pipe(
                tap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token);
                }),
                map(() => this.appendToken(request))
            );
        }

        return of(this.appendToken(request));
    }

    private appendApiKey(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('X-Api-Key', environment.apiKey)
        });
    }

    private appendToken(request: HttpRequest<any>): HttpRequest<any> {
        request = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${ this.authService.getAccessToken() }`)
        });

        return request;
    }

    private tokenNotRequired(request: HttpRequest<any>) {
        let notRequired = false;

        this.skipToken.forEach((endpoint: string) => {
            if (request.url.startsWith(environment.apiUrl + endpoint)) {
                notRequired = true;
            }
        });

        return notRequired;
    }
}
