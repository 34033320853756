import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { GoogleLibPhoneNumberService } from './services';

@Injectable()
export class SharedValidators {

  constructor(
    private googleLibPhoneNumberService: GoogleLibPhoneNumberService,
  ) { }

  validateSalesReps(repsArray: FormArray): ValidationErrors {
    const repsPercentageTotal = repsArray.controls
      .map((control: FormControl) => parseFloat(control.get('percentage').value))
      .reduce((acc: number, percentage: number) => acc + percentage, 0);

    return repsPercentageTotal === 100 ? null : { invalidPercentage: true };
  }

  validatePhoneNumber(countryCodeIso2?: string): ValidatorFn {
    return (phoneControl: AbstractControl): ValidationErrors => {
      let isValid = false;

      if (!phoneControl.value) {
        return null;
      }

      try {
        const phoneNumber = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(phoneControl.value, countryCodeIso2);
        isValid = countryCodeIso2 ?
          this.googleLibPhoneNumberService.phoneNumberUtil.isValidNumberForRegion(phoneNumber, countryCodeIso2) :
          this.googleLibPhoneNumberService.phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {
        isValid = false;
      }

      return isValid ? null : { invalidPhone: true };
    };
  }

  validatePattern(pattern: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      const regExp = new RegExp(pattern);

      return regExp.test(control.value) ? null : { patternMismatch: true };
    };
  }
}
