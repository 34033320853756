import { Component, Input } from '@angular/core';

export interface SkelethonColumn {
  type: string;
  class?: string;
}

export interface SkelethonRow {
  columns: SkelethonColumn[];
}

export interface SkelethonConfig {
  columnClass?: string;
  rowClass?: string;
  rows: SkelethonRow[];
}

export enum SKELETHON_COLUMN_TYPE {
  LINE = 'line-placeholder',
  CIRCLE = 'circle-placeholder'
}

@Component({
  selector: 'app-skelethon',
  templateUrl: './skelethon.component.html'
})
export class SkelethonComponent {
  @Input() skelethonConfig: SkelethonConfig;
}
