import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

class HttpOptions {
  params?: HttpParams | { [param: string]: string | string[]; };
  responseType?: string | any;
  observe?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private eventUnauthorizedRequest: EventEmitter<void> = new EventEmitter();
  onUnauthorizedRequest: Observable<void> = this.eventUnauthorizedRequest.asObservable();

  constructor(private http: HttpClient) { }

  get(path: string, options?: HttpOptions): Observable<any> {
    this.removeEmptyParams(options);

    return this.http.get(this.prepareUrl(path), options)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  post(path: string, body: object = {}, options: object = {}): Observable<any> {
    const _options = { headers: { 'content-type': 'application/json' }, ...options };

    return this.http.post(this.prepareUrl(path), JSON.stringify(body), _options)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  put(path: string, body: object = {}, options: object = {}): Observable<any> {
    const _options = { headers: { 'content-type': 'application/json' }, ...options };

    return this.http.put(this.prepareUrl(path), JSON.stringify(body), _options)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(this.prepareUrl(path))
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  private prepareUrl(endpoint: string) {
    const root = environment.apiUrl.endsWith('/') ? environment.apiUrl.slice(0, -1) : environment.apiUrl;
    const primary = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;

    return `${root}/${primary}`;
  }

  private handleErrors(error: any): Observable<any> {
    if (error.status === 401) {
      this.eventUnauthorizedRequest.emit();
      return new Observable();
    } else {
      return throwError(error);
    }
  }

  private removeEmptyParams(options?: HttpOptions) {
    if (options) {
      if (options.params) {
        Object.keys(options.params).forEach(key => {
          switch (true) {
            case (options.params[key] === null):
            case (options.params[key] === undefined):
            case (typeof options.params[key] === 'string' && options.params[key].trim() === ''):
              delete options.params[key];
              break;
          }
        });
      }
    }
  }
}
