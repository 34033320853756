import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone, path } from 'ramda';
import { map, switchMap, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { PermissionsModel } from '../models/permissions.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private accessToken: string;
    private refreshToken: string;
    private tokenType: string;
    private expiresAt: number;

    private permissions: {
        [key: string]: PermissionsModel
    };

    constructor(private apiService: ApiService, private http: HttpClient) { }

    // --------------- Authentication ---------------

    authenticate(email: string, password: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post(`${environment.apiUrl}oauth?grant_type=password`, JSON.stringify({ email, password }), { headers }).pipe(
            tap((response: any) => {
                this.accessToken = response.access_token;
                this.refreshToken = response.refresh_token;
                this.tokenType = response.token_type;
                this.expiresAt = new Date().getTime() + response.expires_in * 1000;
            }),
            switchMap(() => this.getIdentity())
        );
    }

    getIdentity(): any {
        return this.http.get(`${environment.apiUrl}auth`).pipe(map((response: any) => response.data));
    }

    getAccessToken(): string {
        return this.accessToken;
    }

    getRefreshToken(): string {
        return this.refreshToken;
    }

    isTokenExpired(): boolean {
        return this.expiresAt < new Date().getTime();
    }

    refreshTokens(): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post(`${environment.apiUrl}oauth?grant_type=refresh_token`, JSON.stringify({ refresh_token: this.refreshToken }), { headers }).pipe(
            tap((response: any) => {
                this.accessToken = response.access_token;
                this.tokenType = response.token_type;
                this.expiresAt = new Date().getTime() + response.expires_in * 1000;
            }),
            map(() => this.getAuthenticationDetails())
        );
    }

    isAuthenticated(): boolean {
        return !!this.accessToken;
    }

    clearAll(): void {
        this.accessToken = undefined;
        this.refreshToken = undefined;
        this.tokenType = undefined;
        this.expiresAt = undefined;
        this.permissions = undefined;
    }

    setAuthenticationDetails(accessToken: string, refreshToken: string, tokenType: string, expiresAt: number): void {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.tokenType = tokenType;
        this.expiresAt = expiresAt;
    }

    getAuthenticationDetails() {
        return {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            tokenType: this.tokenType,
            expiresAt: this.expiresAt
        };
    }

    // --------------- Authorization (Permissions) ---------------

    loadPermissions(): Promise<void> {
        return this.apiService.get('permissions').toPromise().then(permissions => this.permissions = permissions);
    }

    getPermissions(module?: string): { [key: string]: PermissionsModel } | PermissionsModel {
        let permissions = null;

        if (module) {
            permissions = path([module], this.permissions);
        } else {
            permissions = clone(this.permissions);
        }

        return permissions;
    }

    isAllowed(permissions: string | string[]): any {
        if (!this.permissions) {
            console.error(`Permissions Error: Permissions are not loaded, you need to call .loadPermissions() before this method.`);
            return;
        }

        let operator = 'AND';

        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        } else if (permissions[0] === 'OR' || permissions[0] === 'AND') {
            operator = permissions.shift();
        }

        let isAllowed = true;

        permissions.forEach(strPath => {
            const value = path(strPath.split('.'), this.permissions);
            if (value === undefined) {
                console.error(`Permissions Error: '${strPath}' path does not exists in permissions object`);
            }
            if (operator === 'AND') {
                isAllowed = isAllowed && !!value;
            } else if (operator === 'OR') {
                isAllowed = isAllowed || !!value;
            }
        });

        return isAllowed;
    }
}
