<ng-container *ngIf="tabs">
  <nav mat-tab-nav-bar class="tabs"
       [color]="selectedTab.color"
       [disableRipple]="true"  [ngClass]="[selectedTab.color ? 'mat-'+selectedTab.color : '']">
    <a mat-tab-link *ngFor="let tab of tabs"
       [active]="selectedTab.id === tab.id"
       [disabled]="tab.disabled"
       [ngClass]="[tab.color ? 'hover-' + tab.color : '']"
       (click)="changeTab(tab)">
      <i *ngIf="tab.icon" [ngClass]="[tab.icon ? tab.icon : '', selectedTab.id === tab.id && tab.color ? 'text-' + tab.color : '']"></i>
      {{ tab.label }}
      <mat-chip-list *ngIf="tab.count !== undefined && tab.count !== null">
        <mat-chip>{{ tab.count }}</mat-chip>
      </mat-chip-list>
    </a>
  </nav>
</ng-container>

<div class="bg-white m-0 container filters-wrapper">
  <div class="d-flex justify-content-between align-items-center">
    <ng-container *ngIf="filters && dependenciesFetched">
      <app-list-filters class="align-self-center flex-grow-1 d-flex flex-nowrap justify-content-between"
                        [filters]="filters"
                        [applied]="appliedFilters"
                        [query]="queryFilters"
                        (addFilter)="addFilter($event)"
                        (removeFilter)="removeFilter($event)"
                        (resetFilters)="resetFilters($event)"></app-list-filters>
    </ng-container>
    <div class="ml-auto search-wrapper d-flex flex-nowrap" [hidden]="!config || !config.search">
      <input #searchInput matInput placeholder="Search" autocomplete="off"
             [ngClass]="{'hidden':!showSearchInput}"
             (keyup.enter)="_searchSubmit($event.target)"/>
      <i *ngIf="showSearchInput && searchInput.value.length >= 1; else searchInputIcon"
          class="icon-close align-self-center show-search" (click)="_clearSearchInput()"></i>
      <ng-template #searchInputIcon>
        <i class="icon-search align-self-center icon-xs text-primary-800"
            (click)="_toggleSearchInput()"></i>
      </ng-template>
    </div>
    <div class="ml-auto search-wrapper d-flex flex-nowrap" [hidden]="!config || !config.export">
      <button mat-icon-button class="close leads-export-tbn" (click)="onExportClick()">
        <mat-icon>file_download</mat-icon>
    </button>
    </div>

  </div>
</div>

<mat-table [dataSource]="data" [ngClass]="{'stickyFooterTable':config.fixedFooter, 'stickyHeader':config.stickyHeaders}">
  <ng-content></ng-content>

  <!-- Define select column -->
  <ng-container matColumnDef="_select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox color="primary"
                    [checked]="selection.hasValue() && _isAllSelected()"
                    [indeterminate]="selection.hasValue() && !_isAllSelected()"
                    (change)="$event ? _masterSelect() : null"></mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let entity">
      <mat-checkbox color="primary"
                    [checked]="selection.isSelected(entity)"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(entity) : null">
      </mat-checkbox>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <!-- Define column for row actions -->
  <ng-container matColumnDef="_actions">
    <mat-header-cell *matHeaderCellDef>
      &nbsp;
    </mat-header-cell>
    <mat-cell *matCellDef="let entity">
      <div class="row-actions-container">
        <div class="row-actions">
          <button mat-icon-button *ngFor="let action of rowActions"
                  [matTooltip]="action.label"
                  (click)="$event.stopPropagation(); action.callback(entity)">
            <i [ngClass]="action.icon"></i>
          </button>
        </div>
      </div>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <!-- Define default header row -->
  <ng-container *ngIf="showHeader && headerRowDefs.length === 0">
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  </ng-container>

  <!-- Define default content row -->
  <ng-container *ngIf="rowDefs.length === 0">
    <mat-row *matRowDef="let entity; columns: displayedColumns; let i = index"
             [id]="entity ? entity._id : 'placeholder-' + i"
             (click)="onRowClick(entity ? entity._id : 'placeholder-' + i)"></mat-row>
  </ng-container>

  <!-- Define default footer row -->
  <ng-container *ngIf="showFooter && footerRowDefs.length === 0">
    <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
  </ng-container>

  <!-- Define default row for no results message -->
  <tr class="no-border mat-row" *matNoDataRow>
    <td class="mat-cell">No matching records were found</td>
  </tr>
</mat-table>

<div class="row table-pagination-wrapper" [ngClass]="{'stickyFooter':config.fixedFooter}">
  <div class="col pr-0">
    <div class="h-100 bulk-actions-wrapper d-flex pl-2 justify-content-start align-items-center bg-white">
      <div *ngIf="bulkActions" [hidden]="selection.isEmpty()">
        <button mat-icon-button *ngFor="let action of bulkActions"
                (click)="action.callback(selection.selected)">
          <i class="{{action.icon}}"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col pl-0">
    <mat-paginator [hidden]="!pagination"
                   [disabled]="loading | async"
                   [length]="total"
                   [hidePageSize]="!pagination?.pageSizeOptions"
                   [pageSize]="pagination?.pageSize"
                   [pageSizeOptions]="pagination?.pageSizeOptions"></mat-paginator>
  </div>
  <app-list-fab *ngIf="fab" [attr.icon]="fab.icon" [attr.color]="fab.color" [children]="fab.children" (fabClick)="onFabClick($event)"></app-list-fab>
</div>