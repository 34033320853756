import { Injectable } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Injectable({
  providedIn: 'root'
})
export class GoogleLibPhoneNumberService {
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  phoneNumberFormat = PhoneNumberFormat;
}
