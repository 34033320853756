<div *ngIf="!collapsed" class="full-side-nav">
  <div class="logo mt-4">
    <span class="d-inline-flex cursor-pointer" [routerLink]="[homeUrl]">
      <mat-icon [svgIcon]="appLogo" class="align-self-center mr-20" color="accent"></mat-icon>
      <span class="align-self-center">CHORUS</span>
    </span>
    <span *ngIf="allowToggle" class="menu-toggle" (click)="toggleMenu()">
      <span class="material-icons-outlined">chevron_left</span>
    </span>
  </div>
  <div class="nav-wrapper">
    <mat-accordion class="sidebar">
      <ng-container  *ngFor="let item of navItems">
        <mat-expansion-panel class="mat-elevation-z" *ifAllowed="item.permissions"  routerLinkActive="active" [disabled]="!item.children?.length" >
          <mat-expansion-panel-header *ngIf="item.children?.length">
            <mat-panel-title expandedHeight="72" collapsedHeight="72">
              <span class="d-flex">
                <i *ngIf="item.icon" class="material-icons-outlined align-self-center">{{item.icon}}</i>
                <span class="align-self-center"> {{ item.text }}</span>
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <mat-expansion-panel-header *ngIf="!item.children?.length" [routerLink]="item.link[0]" [queryParams]="item.link[1]">
              <mat-panel-title expandedHeight="72" collapsedHeight="72"> 
                <span class="d-flex">
                  <i *ngIf="item.icon" class="material-icons-outlined align-self-center">{{item.icon}}</i>
                  <span class="align-self-center"> {{ item.text }}</span>
                </span>
              </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list *ngIf="item.children?.length">
            <ng-container  *ngFor="let child of item.children">
              <a mat-list-item [routerLink]="child.link[0]" [queryParams]="child.link[1]" *ifAllowed="child.permissions" routerLinkActive="active">{{ child.text }}</a>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>

    </mat-accordion>
  </div>
  <div class="more-apps available-apps d-flex cursor-pointer" (click)="openUserMenu()" >
    <span class="text-center rounded-circle d-flex all-apps justify-content-center"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">account_circle</i></span>
    <span class="align-self-center">My Account</span>
  </div>
  <div class="more-apps available-apps d-flex cursor-pointer" (click)="openAppsList()" *ngIf="appsList?.active.length || appsList?.future.length">
    <span class="text-center rounded-circle d-flex all-apps justify-content-center"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">apps</i></span>
    <span class="align-self-center">Switch Application</span>
  </div>
</div>

<div *ngIf="collapsed" class="side-nav">
  <div class="alt-logo mt-4 mb-4 text-center">
    <mat-icon [svgIcon]="appLogo" class="align-self-center mx-auto cursor-pointer" color="accent" [routerLink]="[homeUrl]"></mat-icon>
    <div *ngIf="allowToggle" class="menu-toggle-wrapper">
      <span class="menu-toggle-show m-auto">
        <span class="material-icons-outlined">expand_more</span>
      </span>
      <span class="menu-toggle m-auto" (click)="toggleMenu()">
        <span class="material-icons-outlined">chevron_right</span>
      </span>
    </div>
  </div>
  <div class="nav-wrapper">
    <div>
      <ng-container *ngFor="let item of navItems">
        <div class="nav-item" routerLinkActive="active" *ifAllowed="item.permissions">
            <i  [routerLink]="item.link[0]" [queryParams]="item.link[1]" class="material-icons-outlined">{{ item.icon }}</i>
            <mat-nav-list *ngIf="item.children?.length">
              <ng-container *ngFor="let child of item.children">
                <a mat-list-item [routerLink]="child.link[0]" [queryParams]="child.link[1]" routerLinkActive="active" *ifAllowed="child.permissions">{{ child.text }}</a>
              </ng-container>
            </mat-nav-list>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="text-center more-apps"> 
    <span class="text-center rounded-circle d-flex all-apps m-auto justify-content-center" (click)="openUserMenu()"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">account_circle</i></span>
  </div>
  <div class="text-center more-apps" *ngIf="appsList?.active.length || appsList?.future.length">
    <span class="text-center rounded-circle d-flex all-apps m-auto justify-content-center" (click)="openAppsList()"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">apps</i></span>
  </div>
</div>

<app-more-apps *ngIf="showAppsList" [apps]="appsList" [appLogo]="appLogo" [@fadeInOut]></app-more-apps>
<app-user-menu *ngIf="showUserMenu" [appLogo]="appLogo" [@fadeInOut]></app-user-menu>
