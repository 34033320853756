<div class="pt-4">
  <div class="row align-items-center">
    <mat-form-field class="col">
      <mat-label class="fs-16 lh-21 ml-2 mb-2">{{ placeholder }}</mat-label>
      <input matInput [(ngModel)]="value.search" (keyup.enter)="goToSearch()"/>
      <mat-icon *ngIf="value.search" (click)="$event.stopPropagation(); value.search = ''" matSuffix class="cursor-pointer">clear</mat-icon>
    </mat-form-field>
    <div class="col-auto pr-0">
      <button (click)="goToSearch()" [disabled]="isEmpty" mat-button mat-flat-button color="accent" class="search-button-height w-100">
        <span class="fs-18 font-400 lh-24 px-3">Search</span>
      </button>
    </div>
    <div class="col-auto">
      <div class="d-flex justify-content-end">
        <button (click)="saveSearch()" [disabled]="isEmpty" mat-flat-button color="accent" class="search-button-height search-button-width">
          <span class="material-icons-outlined">bookmark_add</span>
        </button>
      </div>
    </div>
  </div>

  <div class="d-block m-0 py-3">
    <app-collection-filters [filters]="filters" (add)="addFilter($event)" (remove)="removeFilter($event)" class="pr-30 flex-grow-1"></app-collection-filters>
  </div>
</div>