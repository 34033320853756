import { Account } from '../../../modules/account/models/account.model';
import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutService } from '../../layout.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  @Input() appLogo:any;

  showMenu = false;
  currentUser: Account;

  constructor(private accountService: AccountService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    private layoutService: LayoutService) { }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'erpLogo',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/erp_logo.svg")
  );
    this.currentUser = this.accountService.getCurrentAccount();
  }

  logOut() {
    this.accountService.logout();
  }

  onCancel(): void {
    this.layoutService.toggleUserMenu(false);
  }
}
