<div class="dialog-content-wrapper">
  <div class="bg-primary-100 border-bottom rounded-top  border-primary-600 d-flex" mat-dialog-title >
    <div>
      <h1 class="fs-16 lh-18 text-black font-400 mb-0">Credit Card Details</h1>
    </div>
    <button mat-icon-button tabindex="-1" class="close" (click)="onCancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div *ngIf="errorMessage && !loading" class="fs-12 lh-14 font-400 text-white bg-accent-600 dialog-message-wrapper d-flex align-items-center">
    <mat-icon class="mr-2 ml-n2">error_outline</mat-icon>
    {{ errorMessage }}
  </div>

  <mat-dialog-content class="default-size">
    <mat-spinner *ngIf="loading" class="mx-auto my-5" [color]="['accent']"></mat-spinner>

    <div *ngIf="!loading" class="container">
      <div id="card-preview" [ngClass]="{'flipped': flipped}">
        <div id="card">
          <div id="front" [ngClass]="type">
            <div class="number">
              <span class="value" *ngFor="let cardNumberSection of formGroup.get('number').value | map:getCardNumberSections">
                {{ cardNumberSection }}
              </span>
            </div>
            <div class="labels">
              <label>Card Holder</label>
              <label>Expiration</label>
            </div>
            <div class="values">
              <span class="value">{{ this.formGroup.get('holderName').value }}</span>
              <span class="value">{{ this.formGroup.get('expiration').value }}</span>
            </div>
          </div>
          <div id="back" [ngClass]="type">
            <div class="cvv">
              <span class="value">{{ this.formGroup.get('cvv').value }}</span>
            </div>
          </div>
        </div>
      </div>

      <div [formGroup]="formGroup" class="credit-card-form">
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Card Number</mat-label>
              <input matInput ccNumber formControlName="number" id="cc-number" type="tel" autocomplete="cc-number" placeholder="Enter card number here:"
                     (change)="onCardNumberChanged($event)" (focus)="onInputFocus($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Holder Name</mat-label>
              <input matInput formControlName="holderName" appInputPatternOnly="[a-z,A-Z\s]" placeholder="Enter card holder's name here:"
                     (focus)="onInputFocus($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="credit-card-expiration-container">
              <mat-form-field class="w-100 expiration-field">
                <mat-label>Exp.</mat-label> 
                <input matInput ccExp formControlName="expiration" id="cc-exp-date" type="tel" autocomplete="cc-exp" maxlength="7" placeholder="MM/YY"
                       (focus)="onInputFocus($event)">
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <mat-form-field class="w-100">
              <mat-label>CVV</mat-label>
              <input matInput formControlName="cvv" id="cvv" maxlength="4" (focus)="onInputFocus($event)">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="container">
      <div class="row">
        <button [disabled]="loading" mat-flat-button (click)="onCancel()" class="col bg-primary-100" color="default">Cancel</button>
        <button [disabled]="loading" mat-flat-button (click)="onSave()" class="col" color="accent">Save</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
