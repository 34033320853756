import { Injectable } from '@angular/core';

export class LogEntry {
  time: number;
  type: string;
  message: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  static TYPE_HTTP_REQUEST = 'http_request';
  static TYPE_APP_WARNING = 'app_warning';
  static TYPE_APP_ERROR = 'app_error';
  static TYPE_CUSTOM = 'custom_event';

  private logs: LogEntry[];

  constructor() {
    this.clearLogs();
  }

  addLog(type: string, message: string, data: any): void {
    const entry = new LogEntry();

    entry.time = new Date().getTime();
    entry.type = type;
    entry.message = message;
    entry.data = (typeof data !== 'string') ? JSON.stringify(data) : data;

    this.logs.push(entry);
  }

  clearLogs(): void {
    this.logs = [];
  }

  getLogs(type?: string): LogEntry[] {
    return this.logs.filter((entry) => (event) ? type === entry.type : true);
  }
}
