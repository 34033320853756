import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, FormGroupDirective } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

import { Subject } from 'rxjs';

import { CountriesService, GoogleLibPhoneNumberService } from '../../services';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: InputPhoneComponent}],
})
export class InputPhoneComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<InputPhoneComponent> {

  static nextId = 0;
  @HostBinding() id = `input-phone-${InputPhoneComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  // tslint:disable-next-line: no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  get value(): any | null {
    const parsed = this.googleLibPhoneNumberService.phoneNumberUtil.parse(this._selectControl.value + this._inputControl.value);
    const val = this.googleLibPhoneNumberService.phoneNumberUtil.format(parsed, this.googleLibPhoneNumberService.phoneNumberFormat.E164);

    return val;
  }

  set value(value: any | null) {
    try {
      this._phoneNumber = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(value);
      this._selectControl.setValue('+' + this._phoneNumber.getCountryCode());
      this._inputControl.setValue(this._phoneNumber.getNationalNumber());
      this.formatPhoneInput();
    } catch (e) {
      this._phoneNumber = null;
      this._selectControl.setValue('+1');
      this._inputControl.setValue(value);
    }

    this.stateChanges.next();
  }

  get empty() {
    return !this._inputControl?.value && !this._selectControl?.value;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  _disabled = false;

  get errorState(): boolean {
    if (this.ngControl) {
      return this.errorStateMatcher.isErrorState(this.ngControl.control as FormControl, this.ngControl.control.parent as unknown as FormGroupDirective);
    } else {
      return false;
    }
  }

  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('select') _selectElement: ElementRef<HTMLSelectElement>;

  focused = false;
  stateChanges = new Subject<void>();

  _inputControl = new FormControl();
  _selectControl = new FormControl();
  _countryCodes: any[];
  _phoneNumber: libphonenumber.PhoneNumber;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>,
              private errorStateMatcher: ErrorStateMatcher, private countriesService: CountriesService,  private googleLibPhoneNumberService: GoogleLibPhoneNumberService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor.monitor(this._elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this._countryCodes = this.countriesService.getCountriesPhoneCodes();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.input-phone-container');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'select' && (event.target as Element).tagName.toLowerCase() !== 'input') {
      this._focusMonitor.focusVia(this._selectElement, 'program');
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCountryChanged(event: MatSelectChange): void{
    this.onChange(this.value);
    this.stateChanges.next();
  }

  onPhoneTyping(event: InputEvent): void{
    this.onChange(this.value);
    this.stateChanges.next();
  }

  formatPhoneInput() {
    try {
      const parsed = this.googleLibPhoneNumberService.phoneNumberUtil.parse(this._selectControl.value + this._inputControl.value);

      if (parsed.getCountryCode().toString() === '1') {
        const formatted = this.googleLibPhoneNumberService.phoneNumberUtil.format(parsed, this.googleLibPhoneNumberService.phoneNumberFormat.NATIONAL);
        this._inputControl.setValue(formatted);
      } else {
        const formatted = this.googleLibPhoneNumberService.phoneNumberUtil.format(parsed, this.googleLibPhoneNumberService.phoneNumberFormat.INTERNATIONAL);
        this._inputControl.setValue(formatted.replace('+', '').replace(parsed.getCountryCode().toString(), '').trim());
      }
    } catch (e) { }
  }
}
