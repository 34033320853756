import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErpLayouComponent } from './layout/erp/erp.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TicketingLayouComponent } from './layout/ticketing/ticketing.component';
import { PageNotFoundComponent } from './modules/pages/components/page-not-found/page-not-found.component';
import { DemoLayoutComponent } from './layout/demo/demo-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ticketing',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'customers',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard],
    data: { permissions: 'customers.methods.read' }
  },
  {
    path: 'exemptions',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/exemptions/exemptions.module').then(m => m.ExemptionsModule),
    canActivate: [AuthGuard],
    data: { permissions: 'exemptions.methods.read' }
  },
  {
    path: 'sales',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'receivables',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/receivables/receivables.module').then(m => m.ReceivablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payables',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/payables/payables.module').then(m => m.PayablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leads',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/leads/leads.module').then(m => m.LeadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ticketing',
    component: TicketingLayouComponent,
    loadChildren: () => import('./modules/ticketing/ticketing.module').then(m => m.TicketingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'print',
    loadChildren: () => import('./modules/print/print.module').then(m => m.PrintModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'demo',
    component: DemoLayoutComponent,
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'disabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
