
<button [hidden]="isOpen" (click)="open()" matTooltip="Search" mat-icon-button class="text-primary cursor-pointer">
    <mat-icon>search</mat-icon>
</button>

<div [hidden]="!isOpen" class="align-middle" [ngClass]="{ 'open-wrapper d-flex align-items-center': isOpen }">
    <mat-icon>search</mat-icon>
    
    <input #input matInput placeholder="Search ..." [(ngModel)]="value" (keyup.enter)="search(value)" (blur)="onBlur()" class="pl-10">
    
    <mat-icon [ngClass]="{ 'invisible': !value }" (click)="clear()" aria-label="Clear">close</mat-icon>
</div>