import { NgModule, Pipe } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { CreditCardDirectivesModule } from 'angular-cc-library';

import { MaterialModule } from './material.module';
import { SharedValidators } from './shared.validators';

// components
import { ListCollectionComponent } from './components/list/collection/collection.component';
import { ListFiltersComponent } from './components/list/filters/filters.component';
import { ListFabComponent } from './components/list/fab/fab.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ToolTipComponent } from './components/tool-tip/tool-tip.component';
import { SkelethonComponent } from './components/skelethon/skelethon.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { NestedMenuComponent } from './components/nested-menu/nested-menu.component';
import { AutocompleteChipsComponent } from './components/autocomplete-chips/autocomplete-chips.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InputChipsComponent } from './components/input-chips/input-chips.component';
import { InputFilesComponent } from './components/input-files/input-files.component';
import { StepComponent, StepperComponent } from './components/stepper/stepper.component';
import { CollectionComponent } from './components/collection/collection.component';
import { CollectionPaginatorComponent } from './components/collection/paginator/paginator.component';
import { CollectionFiltersComponent } from './components/collection/filters/filters.component';
import { CollectionTabsComponent } from './components/collection/tabs/tabs.component';
import { CollectionResultsComponent } from './components/collection/results/results.component';
import { CollectionSearchComponent } from './components/collection/search/search.component';
import { CollectionSortComponent } from './components/collection/sort/sort.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { SavedSearchesComponent } from './components/saved-searches/saved-searches.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { InputAddressComponent } from './components/input-address/input-address.component';
import { InputDynamicComponent } from './components/input-dynamic/input-dynamic.component';
import { FormDynamicComponent } from './components/form-dynamic/form-dynamic.component';
import { FormAddressComponent } from './components/form-address/form-address.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

// services
import { SmartyStreetsService } from './services/smarty-streets.service';
import { CountriesService } from './services/countries.service';
import { OverlayService } from './services/overlay.service';
import { FormsHelpersService } from './services/forms-helpers.service';

// directives
import { IfAllowedDirective } from 'src/app/core/directives/if-allowed.directive';
import { DragDropDirective } from 'src/app/core/directives/drag-drop.directive';
import { InputPatternOnlyDirective } from 'src/app/core/directives/input-pattern-only.directive';
import { ToolTipDirective } from 'src/app/core/directives/tool-tip.directive';
import { ItemImageDirective } from './directives/item-image.directive';
import { PhoneInputFormatDirective } from './directives/phone-input-format.directive';
import { TableRowExpansionDirective } from './directives/table-row-expansion.directive';

// pipes
import { OptionsFilter } from './pipes/options-filter.pipe';
import { ReadableBooleanPipe } from './pipes/readable-boolean.pipe';
import { PreserveNewLinePipe } from './pipes/preserve-new-line.pipe';
import { NotAvailableInfoPipe } from './pipes/not-available-info.pipe';
import { MapIdsToValuePipe } from './pipes/map-ids-to-values.pipe';
import { JoinArrayProperty } from './pipes/join-array-property.pipe';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MapPipe } from './pipes/map.pipe';
import { ViewAsPipe } from './pipes/view-as.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { NgpSortModule } from 'ngp-sort-pipe';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoExtendsPipe extends TimeAgoPipe {}

@NgModule({
  declarations: [
    // components
    AdvancedSearchComponent,
    AutocompleteChipsComponent, // TODO: replace this component with InputChips
    CollectionComponent,
    CollectionFiltersComponent,
    CollectionPaginatorComponent,
    CollectionResultsComponent,
    CollectionSearchComponent,
    CollectionSortComponent,
    CollectionTabsComponent,
    ConfirmActionComponent,
    CreditCardComponent,
    DialogComponent,
    FileUploadComponent, // TODO: replace this component with InputFiles
    FormAddressComponent,
    FormControlErrorComponent,
    FormDynamicComponent,
    InputAddressComponent,
    InputChipsComponent,
    InputDynamicComponent,
    InputFilesComponent,
    ListCollectionComponent,
    ListFabComponent, // TODO: remove this component (not needed in new design)
    ListFiltersComponent,
    NestedMenuComponent,
    PhoneInputComponent, // TODO: rename into InputPhone
    ProgressSpinnerComponent,
    SavedSearchesComponent,
    SkelethonComponent,
    ToolTipComponent,
    StepComponent,
    StepperComponent,
    InputPhoneComponent,
    NotFoundComponent,

    // directives
    ItemImageDirective,
    DragDropDirective, // TODO: move files of this component from core to shared
    InputPatternOnlyDirective, // TODO: move files of this component from core to shared
    PhoneInputFormatDirective,
    TableRowExpansionDirective,
    ToolTipDirective, // TODO: move files of this component from core to shared
    IfAllowedDirective,

    // pipes
    ArrayFilterPipe,
    FormatPhonePipe,
    JoinArrayProperty,
    MapPipe,
    MapIdsToValuePipe,
    NotAvailableInfoPipe,
    OptionsFilter, // TODO: remove this pipe (not needed after refactoring of the listing component)
    PreserveNewLinePipe,
    ReadableBooleanPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    TimeAgoExtendsPipe,
    ViewAsPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    OverlayModule,
    NgpSortModule
  ],
  exports: [
    // components
    AdvancedSearchComponent,
    AutocompleteChipsComponent,
    CollectionComponent,
    CollectionFiltersComponent,
    CollectionPaginatorComponent,
    CollectionResultsComponent,
    CollectionSearchComponent,
    CollectionSortComponent,
    CollectionTabsComponent,
    ConfirmActionComponent,
    CreditCardComponent,
    DialogComponent,
    FileUploadComponent,
    FormAddressComponent,
    FormControlErrorComponent,
    FormDynamicComponent,
    InputAddressComponent,
    InputChipsComponent,
    InputDynamicComponent,
    InputFilesComponent,
    InputPhoneComponent,
    ListCollectionComponent,
    ListFabComponent,
    ListFiltersComponent,
    NestedMenuComponent,
    PhoneInputComponent,
    ProgressSpinnerComponent,
    SavedSearchesComponent,
    SkelethonComponent,
    ToolTipComponent,
    StepComponent,
    StepperComponent,
    NotFoundComponent,

    // directives
    ItemImageDirective,
    DragDropDirective,
    InputPatternOnlyDirective,
    PhoneInputFormatDirective,
    TableRowExpansionDirective,
    ToolTipDirective,
    IfAllowedDirective,

    // pipes
    ArrayFilterPipe,
    FormatPhonePipe,
    JoinArrayProperty,
    MapPipe,
    MapIdsToValuePipe,
    NotAvailableInfoPipe,
    OptionsFilter,
    PreserveNewLinePipe,
    ReadableBooleanPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    TimeAgoExtendsPipe,
    ViewAsPipe,
    NgpSortModule
  ],
  providers: [
    SmartyStreetsService,
    CountriesService,
    OverlayService,
    SharedValidators,
    FormsHelpersService,
    DecimalPipe,
    CurrencyPipe,
    DatePipe
  ]
})
export class SharedModule { }
