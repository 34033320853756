import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { GoogleLibPhoneNumberService } from '../services';

@Directive({
  selector: '[appPhoneInputFormat]'
})
export class PhoneInputFormatDirective {
  constructor(
    private el: ElementRef,
    private googleLibPhoneNumberService: GoogleLibPhoneNumberService
  ) {
  }

  @HostListener('change')
  onChange() {
    const element: HTMLInputElement = this.el.nativeElement

    try {
      const phoneNumberInstance = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(element.value);
      element.value = this.googleLibPhoneNumberService.phoneNumberUtil.formatOutOfCountryCallingNumber(phoneNumberInstance);
      return element.value;
    } catch (err) {
      return;
    }
  }
}
