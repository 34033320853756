import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, concat } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { InMemoryStorage } from '../models/in-memory.storage';

export const STORAGE_TYPE_IN_MEMORY = 'in-memory';
export const STORAGE_TYPE_SESSION = 'session';
export const STORAGE_TYPE_PERSISTENT = 'persistent';

export class StorageOptions {
  storage: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private memoryStorage: InMemoryStorage;

  constructor() {
    this.memoryStorage = new InMemoryStorage();
  }

  preload() {
    return concat(
      this.loadPersistentStorage(),
      this.loadSessionStorage()
    ).pipe(catchError(() => of()));
  }

  set(key: string, value: any, options: StorageOptions = { storage: STORAGE_TYPE_IN_MEMORY }): Observable<void> {
    if (options.storage === STORAGE_TYPE_PERSISTENT) {
      return concat(
        this.setPersistent(key, value),
        this.setInMemory(key, value)
      );
    } else if (options.storage === STORAGE_TYPE_SESSION) {
      return concat(
        this.setSession(key, value),
        this.setInMemory(key, value)
      );
    } else {
      return this.setInMemory(key, value);
    }
  }

  get(key: string): any {
    return this.memoryStorage.getItem(key);
  }

  remove(key: string) {
    return concat(
      this.removePersistent(key),
      this.removeSession(key),
      this.removeFromMemory(key)
    );
  }

  private setInMemory(key: string, value: any): Observable<void> {
    return this.setInStorage(key, value, this.memoryStorage, false);
  }

  private setSession(key: string, value: any): Observable<void> {
    return this.setInStorage(key, value, sessionStorage, true);
  }

  private setPersistent(key: string, value: any): Observable<void> {
    return this.setInStorage(key, value, localStorage, true);
  }

  private setInStorage(key: string, value: any, storage: Storage, stringify: boolean): Observable<void> {
    return new Observable((observer) => {
      try {
        if (stringify) {
          value = JSON.stringify(value);
        }
        storage.setItem(key, value);
        observer.next();
        observer.complete();
      } catch (error) {
        // TODO: log error using an internal logger service
        observer.error(error);
        observer.complete();
      }
    });
  }

  private getFromStorage(key: string, storage: Storage, parse: boolean): Observable<any> {
    return new Observable((observer) => {
      try {
        let value = storage.getItem(key);
        if (parse) {
          value = JSON.parse(value);
        }
        observer.next(value);
        observer.complete();
      } catch (error) {
        // TODO: log error using an internal logger service
        observer.error(error);
        observer.complete();
      }
    });
  }

  private removePersistent(key: string): Observable<void> {
    return this.removeFromStorage(key, localStorage);
  }

  private removeSession(key: string): Observable<void> {
    return this.removeFromStorage(key, sessionStorage);
  }

  private removeFromMemory(key: string): Observable<void> {
    return this.removeFromStorage(key, this.memoryStorage);
  }

  private removeFromStorage(key: string, storage: Storage): Observable<void> {
    return new Observable((observer) => {
      try {
        storage.removeItem(key);
        observer.next();
        observer.complete();
      } catch (error) {
        // TODO: log error using an internal logger service
        observer.error(error);
        observer.complete();
      }
    });
  }

  private storageKeys(storage: Storage) {
    const keys = [];

    for (let index = 0; index < storage.length; index++) {
      keys.push(storage.key(index));
    }

    return keys;
  }

  private loadPersistentStorage() {
    const obs = [];
    this.storageKeys(localStorage).forEach(key => obs.push(this.getFromStorage(key, localStorage, true).pipe(tap((value) => { this.setInMemory(key, value).subscribe(); }))));
    return forkJoin(obs);
  }

  private loadSessionStorage() {
    const obs = [];
    this.storageKeys(sessionStorage).forEach(key => obs.push(this.getFromStorage(key, sessionStorage, true).pipe(tap((value) => { this.setInMemory(key, value).subscribe(); }))));
    return forkJoin(obs);
  }
}
