import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { LayoutService, MainMenuChangeEvent } from '../../layout.service';
import { NavItem } from './nav-item.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Input() navItems: NavItem[];
  @Input() appsList: any;
  @Input() appLogo: any;
  @Input() homeUrl: any;

  collapsed = false;
  allowToggle = true;
  showAppsList: boolean;
  showUserMenu: boolean;

  menuChangeSubscription: Subscription;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.menuChangeSubscription = this.layoutService.mainMenuChange.subscribe((event: MainMenuChangeEvent) => {
      if (typeof event.collapsed === 'boolean') { this.collapsed = event.collapsed; }
      if (typeof event.toggle === 'boolean') { this.allowToggle = event.toggle; }
    });
    this.layoutService.showAvailableAppsListEmitter.subscribe((val) => {
      this.showAppsList = val;
    });
    this.layoutService.showUserMenuEmitter.subscribe((val) => {
      setTimeout(() => {
        this.showUserMenu = val;
      }, 100);
    });

    window.addEventListener('resize', this.onWindowResize.bind(this), true);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.onWindowResize.bind(this), true);
    this.menuChangeSubscription.unsubscribe();
  }

  openAppsList() {
    this.layoutService.toggleAvailableAppsList(true);
  }
  openUserMenu(){
    this.layoutService.toggleUserMenu(true);
  }
  toggleMenu() {
    if (this.collapsed) {
      this.layoutService.expandMainMenu();
    } else {
      this.layoutService.collapseMainMenu();
    }
  }

  private onWindowResize(event: any) {
    const innerWidth = event.srcElement.innerWidth;

    if (innerWidth < 1440) {
      this.layoutService.collapseMainMenu();
    } else {
      this.layoutService.expandMainMenu();
    }
  }
}
