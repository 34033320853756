<div class="py-4">
  <div class="text-black pb-1 fs-16 lh-24 search-label">Saved Searches:</div>
  <div class="saved-searches-list w-100">
    <mat-table mat-table matSort [dataSource]="savedSearches | arrayFilter:filterByModule:module" class="app-collection-results" [ngClass]="{ 'no-border': savedSearches.length === 1 }">
      <ng-container matColumnDef="savedSearch">
        <mat-cell *matCellDef="let savedSearch">
          <div class="mat-cell-contents">
            <span class=" text-accent-A200 cursor-pointer">{{ savedSearch.name }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="_actions">
        <mat-cell *matCellDef="let savedSearch">
          <div class="mat-cell-contents">
            <div class="row-actions-container">
              <div class="row-actions p-0">
                <button mat-icon-button matTooltip="Delete" (click)="$event.stopPropagation(); deleteSearch(savedSearch)" class="ml-10">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let savedSearch; columns: ['savedSearch', '_actions']" (click)="goToSearch(savedSearch)"></mat-row>
      <tr class="no-border mat-row" *matNoDataRow>
        <td class="mat-cell fs-12">No saved searches available</td>
      </tr>
    </mat-table>

    <!-- <div *ngIf="savedSearches.length > 5" class="load-more-button mt-2 mb-2 text-center"
      (click)="showMoreSavedSearches = !showMoreSavedSearches">
      <span class="material-icons text-primary">{{ showMoreSavedSearches ? 'expand_less' : 'more_horiz' }}</span>
    </div> -->
  </div>
</div>