import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatFooterRowDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';
import { SkelethonConfig, SKELETHON_COLUMN_TYPE } from '../../skelethon/skelethon.component';

import { CollectionResultsControl, CollectionSortControl } from '../collection.component';

@Component({
  selector: 'app-collection-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  providers: [
    { provide: CollectionResultsControl, useExisting: CollectionResultsComponent },
    { provide: CollectionSortControl, useExisting: CollectionResultsComponent }
  ]
})
export class CollectionResultsComponent<T> implements OnInit, AfterContentInit, CollectionResultsControl<T>, CollectionSortControl {

  @Input() results: T[] = [];
  @Input() columnsToDisplay: string[] = [];

  @ViewChild(MatTable, { static: true }) matTable: MatTable<T>;

  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) contentRowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatFooterRowDef) footerRowDefs: QueryList<MatFooterRowDef>;

  loading: boolean;
  showHeader: boolean;
  showFooter: boolean;
  resultsListSkeleton: SkelethonConfig;
  resultsListHeaderSkeleton: SkelethonConfig;

  private onChange = (_: any) => {};

  constructor(private matSort: MatSort) { }

  ngOnInit(): void { 

    const resultsListRow = {
      columnClass: 'justify-content-between mx-0 px-0',
      columns: [
        {type: SKELETHON_COLUMN_TYPE.CIRCLE, class: 'col-3 px-5'},
        {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
        {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
        {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
        {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-6'},
        {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-6'}
      ]
    };

    this.resultsListSkeleton = {
      rowClass: ' row item mx-0 px-0 pt-25 pb-25 justify-content-between row-border-bottom',
      rows: Array(30).fill(resultsListRow)
    };

    this.resultsListHeaderSkeleton = {
      rowClass: ' row item mx-0 px-0 pt-10 pb-10 justify-content-between row-border-top row-border-bottom bg-grey-100',
      rows: [{
        columns: [
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-3'},
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-7'},
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-6'},
          {type: SKELETHON_COLUMN_TYPE.LINE, class: 'col-6'}
        ]
      }]
    }

  }

  ngAfterContentInit(): void {
    let definedColumnNames = [];

    this.columnDefs.forEach(columnDef => {
      definedColumnNames.push(columnDef.name);
      this.matTable.addColumnDef(columnDef);
      this.showHeader = this.showHeader || !!columnDef.headerCell;
      this.showFooter = this.showFooter || !!columnDef.footerCell;
    });

    if (this.columnsToDisplay.length === 0) {
      this.columnsToDisplay = definedColumnNames;
    }

    // Add custom defined rows
    this.headerRowDefs.forEach(headerRowDef => this.matTable.addHeaderRowDef(headerRowDef));
    this.contentRowDefs.forEach(rowDef => this.matTable.addRowDef(rowDef));
    this.footerRowDefs.forEach(footerRowDef => this.matTable.addFooterRowDef(footerRowDef));

    if (this.matSort) {
      this.matSort.sortChange.subscribe((s) => {
        if (s.direction) {
          this.onChange(`${ s.direction === 'desc' ? '-' : '' }${ s.active }`);
        } else {
          this.onChange(null);
        }
      });
    }
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  setResults(results: T[]): void {
    this.results = results;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setSort(sort: string) {
    if (sort) {
      this.matSort.active = sort.startsWith('-') ? sort.slice(1) : sort;
      this.matSort.direction = sort.startsWith('-') ? 'desc' : 'asc';
    } else {
      this.matSort.active = '';
      this.matSort.direction = '';
    }
  }
}
