import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      if (route.data.permissions) {
        return this.authService.isAllowed(route.data.permissions);
      } else {
        return true;
      }
    } else {
      this.router.navigate(['account', 'login'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}
