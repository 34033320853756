export class InMemoryStorage implements Storage {

    private data: { [key: string]: any };

    constructor() {
        this.clear();
    }

    get length() {
        return Object.keys(this.data).length;
    }

    clear() {
        this.data = {};
    }

    setItem(key: string, value: any) {
        this.data[key] = value;
    }

    getItem(key: string) {
        return this.data[key];
    }

    removeItem(key: string) {
        delete this.data[key];
    }

    key(index: number) {
        return null;
    }
}
