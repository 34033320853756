import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArrayProperty'
})
export class JoinArrayProperty implements PipeTransform {

  transform(value: any[], property: string): string {
    return value && value.length ? value
      .map((item: any) => item[property])
      .join(', ')
      : '';
  }

}
