import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

export interface Item {
  name: string;
  children?: Item[];
}

@Component({
  selector: 'app-nested-menu',
  templateUrl: './nested-menu.component.html'
})
export class NestedMenuComponent {
  @Input() items: Item[];

  @ViewChild('itemsMenu', {static: true}) public itemsMenu: any;

  @Output()
  itemClicked: EventEmitter<any> = new EventEmitter();

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  itemClick(item) {
    this.itemClicked.emit(item);
  }

  menuClosed() {
    this.closed.emit();
  }
}
