import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    private events: { [key: string]: EventEmitter<any> };

    constructor() {
        this.events = {};
    }

    subscribe(event: string, callback: any) {
        this.createEmitterIfEmpty(event);
        return this.events[event].subscribe(callback);
    }

    emit(event: string, value?: any) {
        this.createEmitterIfEmpty(event);
        this.events[event].emit(value);
    }

    private createEmitterIfEmpty(event: string) {
        if (this.events[event] === undefined) {
            this.events[event] = new EventEmitter();
        }
    }
}
