<div class="page-content-wrapper bg-white" [ngClass]="{'side-menu-extended':!isMenuCollapsed}">

    <div class="sidebar">
      <app-sidebar [navItems]="navItems" [appsList]="appsList" [appLogo]="'erpLogo'" [homeUrl]="'/demo'"></app-sidebar>
    </div>
  
    <div class="content-wrapper" [ngClass]="'visibleHeader'">
      <div class="main-content-wrapper">
        <app-header></app-header>
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  
  </div>