import { EventEmitter, Injectable } from '@angular/core';
import { tap, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

import { ApiService } from 'src/app/core/services/api.service';
import { StorageService, STORAGE_TYPE_PERSISTENT, STORAGE_TYPE_SESSION } from 'src/app/core/services/storage.service';
import { Account } from '../models/account.model';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private eventLogout: EventEmitter<void> = new EventEmitter();
  onLogout: Observable<void> = this.eventLogout.asObservable();

  private currentAccount: Account;

  constructor(private api: ApiService, private storage: StorageService, private authService: AuthService) { }

  tryAutoLogin() {
    const auth = this.storage.get('auth');

    if (auth) {
      this.authService.setAuthenticationDetails(auth.accessToken, auth.refreshToken, auth.tokenType, auth.expiresAt);
      return Promise.all([
        this.updateAccountDetails(),
        this.authService.loadPermissions()
      ]).catch(() => null);
    } else {
      return Promise.resolve();
    }
  }

  login(email: string, password: string) {
    return this.authService.authenticate(email, password).pipe(
      tap(() => {
        this.storage.set('auth', this.authService.getAuthenticationDetails(), { storage: STORAGE_TYPE_SESSION }).toPromise();
      }),
      map((identity: any) => {
        this.currentAccount = new Account().hydrate(identity);
        return this.currentAccount;
      }),
      switchMap(() => from(this.authService.loadPermissions())),
      map(() => this.currentAccount)
    );
  }

  rememberLogin() {
    return this.storage.set('auth', this.authService.getAuthenticationDetails(), { storage: STORAGE_TYPE_PERSISTENT }).toPromise();
  }

  logout() {
    return this.storage.remove('auth').pipe(tap(() => {
      this.authService.clearAll();
      this.currentAccount = null;
      this.eventLogout.emit();
    })).toPromise();
  }

  getCurrentAccount(): Account | null {
    return this.currentAccount;
  }

  requestPasswordReset(email: string) {
    return this.api.post('auth/reset', { email });
  }

  updateAccountDetails(): Promise<Account> {
    return this.authService.getIdentity().pipe(
        map((identity: any) => new Account().hydrate(identity)),
        tap((account: Account) => this.currentAccount = account)
      ).toPromise();
  }

  getApplicationData() {
    return this.getCurrentAccount().appData;
  }

  saveApplicationData(appData: Account['appData']) {
    return this.api.put(`users/${this.currentAccount._id}/application/userData`, appData).toPromise();
  }
}
