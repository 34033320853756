import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { CollectionFilter, CollectionFiltersControl } from '../collection.component';

export const FILTER_TYPE_INPUT = 'input';
export const FILTER_TYPE_RADIO = 'radio';
export const FILTER_TYPE_MULTI_SELECT = 'multiSelect';
export const FILTER_TYPE_RANGE = 'range';

export interface CollectionFilterDetails {
  name: string;
  label: string;
  type: string;
  applied?: boolean;
  values?: (number | string | boolean)[];
  queries: string[];
  mapValues?: (values: any[]) => any[];
  onApply?: (values: any[]) => void;
  onRemove?: () => void;
  searchEnabled?: boolean;
  input?: {
    type?: 'text' | 'email' | 'number' | 'tel';
    placeholder?: string;
  };
  options?: {
    label: string;
    value: number | string | boolean;
  }[];
  range?: {
    type?: 'number' | 'date';
    min?:number,
    max?:number
  };
}

@Component({
  selector: 'app-collection-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [{ provide: CollectionFiltersControl, useExisting: CollectionFiltersComponent }]
})
export class CollectionFiltersComponent implements OnInit, OnDestroy, CollectionFiltersControl {

  TYPE_INPUT = FILTER_TYPE_INPUT;
  TYPE_RADIO = FILTER_TYPE_RADIO;
  TYPE_MULTI_SELECT = FILTER_TYPE_MULTI_SELECT;
  TYPE_RANGE = FILTER_TYPE_RANGE;

  @Input() filters: CollectionFilterDetails[];

  // tslint:disable-next-line: no-output-rename
  @Output('add') addEvent: EventEmitter<CollectionFilterDetails[]> = new EventEmitter();
  // tslint:disable-next-line: no-output-rename
  @Output('remove') removeEvent: EventEmitter<CollectionFilterDetails[]> = new EventEmitter();
  // tslint:disable-next-line: no-output-rename
  @Output('change') changeEvent: EventEmitter<CollectionFilterDetails[]> = new EventEmitter();

  selected: CollectionFilterDetails;
  unapplied: CollectionFilterDetails[];
  applied: CollectionFilterDetails[];

  loading: boolean;
  clickedInside: boolean;
  openedOverlay: boolean;
  isOpen: boolean;
  searchTerm = '';

  private onAdd = (_: any) => {};
  private onRemove = (_: any) => {};

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll.bind(this), true);

    this.updateAppliedAndUnappliedLists();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

  private onScroll() {
    if (this.elementRef.nativeElement.getBoundingClientRect().top <= -this.elementRef.nativeElement.offsetHeight && this.isOpen) {
      this.isOpen = false;
    }
  }

  // === Functions required for CollectionFiltersControl ===

  registerOnChange(fn: any): void { }

  registerOnAdd(fn: any): void {
    this.onAdd = fn;
  }

  registerOnRemove(fn: any): void {
    this.onRemove = fn;
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }

  getFilters() {
    return this.mapToCollectionFilters(this.filters.filter(filter => filter.applied));
  }

  setValues(values: CollectionFilter[]): void {
    this.filters.forEach(filter => {
      filter.applied = false;
      const found = values.find(el => el.name === filter.name);
      if (found) {
        switch (filter.type) {
          case FILTER_TYPE_MULTI_SELECT:
          case FILTER_TYPE_RANGE:
            filter.values = found.value.split(',');
            break;
          default:
            filter.values = [found.value];
            break;
        }
        filter.applied = true;
      }
    });

    this.updateAppliedAndUnappliedLists();
  }

  // === Functions to interact with the component ===

  apply(filter: CollectionFilterDetails) {
    if (!this.validateValue(filter.values)) {
      return;
    }

    filter.applied = true;
    this.searchTerm = '';

    if (this.isOpen && !!this.selected) {
      this.isOpen = false;
      this.selected = null;
    }

    if (filter.onApply) {
      filter.onApply(filter.values);
    }

    this.updateAppliedAndUnappliedLists();

    this.addEvent.emit([filter]);
    this.onAdd(this.mapToCollectionFilters([filter]));
  }

  remove(filter: CollectionFilterDetails) {
    filter.applied = false;

    if (filter.onRemove) {
      filter.onRemove();
    }

    this.updateAppliedAndUnappliedLists();

    this.removeEvent.emit([filter]);
    this.onRemove(this.mapToCollectionFilters([filter]));
  }

  reset() {
    const _filters = this.filters.filter((el) => el.applied === true);
    _filters.forEach(filter => filter.applied = false);

    this.updateAppliedAndUnappliedLists();

    this.removeEvent.emit(_filters);
    this.onRemove(this.mapToCollectionFilters(_filters));
  }

  open() {
    this.selected = null;
    this.isOpen = true;
  }

  select(filter: CollectionFilterDetails) {
    filter.values = [];
    this.selected = filter;
  }

  getQuery(): string {
    return this.getFilters().map((filter: CollectionFilter) => filter.query).join(' and ');
  }

  // === Internal functions ===

  openOverlay() {
    this.openedOverlay = true;
  }

  closedOverlay() {
    this.openedOverlay = false;
  }

  clickInside() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (!this.clickedInside && !this.openedOverlay && this.isOpen) {
      this.isOpen = false;
    }
    this.clickedInside = false;
  }

  filterOptionBySearch(option: CollectionFilterDetails['options'][0], searchTerm: string) {
    return option.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  }

  startOfDay(value: Date | null) {
    return (value instanceof Date) ? value.setHours(0, 0, 0, 0) : value;
  }

  endOfDay(value: Date | null) {
    return (value instanceof Date) ? value.setHours(23, 59, 59, 999) : value;
  }

  getFilterPrettyValue(filter: CollectionFilterDetails): string {
    let value = 'N/A';

    switch (filter.type) {
      case FILTER_TYPE_INPUT:
        value = filter.values.toString();
        break;

      case FILTER_TYPE_RADIO:
        value = filter.options.filter(option => filter.values[0].toString() === option.value.toString()).map(el => el.label).toString();
        break;

      case FILTER_TYPE_MULTI_SELECT:
        value = filter.options.filter(option => filter.values.find(val => val.toString() === option.value.toString())).map(el => el.label).join(', ');
        break;

      case FILTER_TYPE_RANGE:
        const values = filter.values.map(val => {
          if (val === '' || (typeof val !== 'string' && typeof val !== 'number' && typeof val !== 'boolean')) {
            return null;
          } else if (filter.range.type === 'date') {
            const date = new Date();
            date.setTime(val as number);
            return date.toLocaleDateString();
          } else {
            return val.toString();
          }
        });
        if (!!values[0] && !!values[1]) {
          value = 'from ' + values[0] + ' to ' + values[1];
        } else if (!values[0]) {
          value = (filter.range.type === 'date' ? 'before ' : 'lower than ') + values[1];
        } else if (!values[1]) {
          value = (filter.range.type === 'date' ? 'from ' : 'greater than ') + values[0];
        }
        break;

      default:
        return 'N/A';
    }

    return value;
  }

  setFilterValue(filter: CollectionFilterDetails, value: any, index?: number) {
    if (!index) { index = 0; }
    filter.values[index] = (value instanceof Date) ? value.getTime() : value;
  }

  toggleFilterValue(filter: CollectionFilterDetails, value: CollectionFilterDetails['values'][0]) {
    if (!Array.isArray(filter.values)) {
      filter.values = [value];
    } else if (filter.values.includes(value)) {
      filter.values = filter.values.filter(el => el !== value);
    } else {
      filter.values = filter.options.filter(el => [].concat(filter.values, [value]).includes(el.value)).map(el => el.value);
    }
  }

  private validateValue(value: any[]): boolean {
    const valid = value.filter(el => !(el === '' || (typeof el !== 'string' && typeof el !== 'number' && typeof el !== 'boolean')));

    return valid.length > 0;
  }

  private updateAppliedAndUnappliedLists() {
    this.applied = this.filters.filter(filter => filter.applied);
    this.unapplied = this.filters.filter(filter => !filter.applied);
  }

  private mapToCollectionFilters(filters: CollectionFilterDetails[]): CollectionFilter[] {
    return filters.map(filter => Object.assign({
      name: filter.name,
      value: filter.values.join(','),
      query: this.prepareFilterQuery(filter),
    }));
  }

  private prepareFilterQuery(filter: CollectionFilterDetails): string {
    const queries = [];
    let values = filter.values ? filter.values.slice(0) : [];

    if (filter.mapValues) {
      values = filter.mapValues(values);
    }

    filter.queries.forEach((query: string) => {
      if (query.indexOf('{{value}}') >= 0) {
        const value = values.shift();

        if (value) {
          queries.push(query.replace(/{{value}}/g, value.toString()));
        }
      } else {
        queries.push(query);
      }
    });

    return queries.join(' and ');
  }

}
