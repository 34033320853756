import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Country, SmartyStreetsCheckResult } from 'src/app/shared/interfaces';
import { SmartyStreetsService } from 'src/app/shared/services';

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string;
  fax?: string;
  email: string;
  web: string;
  rdi: string;
  notes: string;
  isValid: string;
}

enum ADDRESS_VALID_TYPES {
  INVALID = 'invalid',
  API_VALIDATED = 'api_validated',
  USER_VALIDATED = 'user_validated'
}

@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss']
})
export class FormAddressComponent implements OnInit {

  @Input() formGroup: FormGroupTyped<Address>;
  @Input() countries: Country[];

  disabled: boolean;
  selectedCountry: Country;
  suggestions: SmartyStreetsCheckResult[] = [];
  addressChecked = false;

  constructor(@Optional() @Self() public ngControl: NgControl, private smartyStreetService: SmartyStreetsService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.updateStateFieldByCountry(this.formGroup.get('country').value);
    this.formGroup.get('country').valueChanges.subscribe(this.updateStateFieldByCountry.bind(this));
  }

  clearStateInput() {
    this.formGroup.get('state').setValue('');
    this.formGroup.get('state').markAsUntouched();
    this.formGroup.get('state').updateValueAndValidity();
  }

  updateStateFieldByCountry(countryCodeIso3: string) {
    this.selectedCountry = this.countries.find(c => countryCodeIso3 === c.iso3);
    if (this.selectedCountry?.states?.length > 0) {
      this.formGroup.get('state').setValidators([Validators.required]);
    } else {
      this.formGroup.get('state').setValidators([]);
    }
    this.formGroup.get('state').updateValueAndValidity();
  }

  onAddressChanged() {
    this.suggestions = [];
    this.addressChecked = false;

    if (this.formGroup.get('country').value === 'USA') {
      this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.INVALID);
    } else {
      this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.USER_VALIDATED);
    }
  }

  verifyAddress() {
    this.smartyStreetService.checkUSAAddress(
      this.formGroup.get('city').value,
      this.formGroup.get('state').value,
      this.formGroup.get('zip').value,
      this.formGroup.get('address').value)
    .toPromise().then((smartyResponse: SmartyStreetsCheckResult[]) => {
      this.suggestions = smartyResponse;
      const firstMatch = this.suggestions && this.suggestions[0];

      const exactMatchFound = firstMatch
        && firstMatch.city === this.formGroup.get('city').value
        && firstMatch.address === this.formGroup.get('address').value
        && this.formGroup.get('zip').value.indexOf(firstMatch.zip)
        && firstMatch.state === this.formGroup.get('state').value;

      if (exactMatchFound) {
        if (firstMatch.plus4code) {
          this.formGroup.get('zip').setValue(`${firstMatch.zip}-${firstMatch.plus4code}`);
        }
        this.formGroup.get('rdi').setValue(firstMatch.rdi || '');
        this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.API_VALIDATED);
      } else {
        this.addressChecked = true;
      }
    }).catch((e: any) => {
      this.snackBar.open(e.error.message, null, { duration: 7000, panelClass: ['bg-danger', 'text-white'] });
    });
  }

  onUserValidationChange({ checked }: MatCheckboxChange): void {
    if (checked) {
      this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.USER_VALIDATED);
    } else {
      this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.INVALID);
    }
  }

  useSuggestion(smartySuggestion: SmartyStreetsCheckResult) {
    this.formGroup.get('address').setValue(smartySuggestion.address);
    this.formGroup.get('city').setValue(smartySuggestion.city);
    this.formGroup.get('zip').setValue(`${smartySuggestion.zip}-${smartySuggestion.plus4code}`);
    this.formGroup.get('state').setValue(smartySuggestion.state);
    this.formGroup.get('rdi').setValue(smartySuggestion.rdi);
    this.formGroup.get('isValid').setValue(ADDRESS_VALID_TYPES.API_VALIDATED);

    this.suggestions = [];
    this.addressChecked = false;
  }
}
