// tslint:disable: no-input-rename
// tslint:disable: no-output-rename

import { AfterContentInit, Component, ContentChild, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CollectionHandler } from 'src/app/core/models/collection-handler.model';

// --------------------- Collection Filters ---------------------

export interface CollectionFilter {
  name: string;
  value?: string;
  query?: string;
}

// --------------------- Collection Controls ---------------------

export abstract class CollectionAbstractControl {
  /**
   * This function is called by the collection component when the loading status changes.
   * Can be used to update the component accordingly based on the loading state.
   */
  abstract setLoadingState(value: boolean): void;
} 

// --------------------- Control: Filters ---------------------

export abstract class CollectionFiltersControl extends CollectionAbstractControl {
  /**
   * Registers a callback function that is called when one of the filters from the 
   * list is changed from the UI
   *
   * When implementing the `registerOnChange` method in your own filters list control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnChange(fn: any): void;
  /**
   * Registers a callback function that is called when a new filter is added to the list
   * from the UI
   *
   * When implementing the `registerOnAdd` method in your own filters list control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnAdd(fn: any): void;
  /**
   * Registers a callback function that is called when a filter is removed from the list
   * from the UI
   *
   * When implementing the `registerOnRemove` method in your own filters list control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnRemove(fn: any): void;
  /**
   * Sets the values for applied filters.
   */
  abstract setValues(values: CollectionFilter[]): void;
  /**
   * Returns the current filters/queries that are applied.
   */
  abstract getFilters(): CollectionFilter[];
}

// --------------------- Control: Paginator ---------------------

export abstract class CollectionPaginatorControl extends CollectionAbstractControl {
  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   *
   * When implementing the `registerOnChange` method in your own paginator control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnChange(fn: any): void;
  /**
   * Sets the current page number.
   */
  abstract setPage(value: number): void;
  /**
   * Sets the current limit number.
   */
  abstract setLimit(value: number): void;
  /**
   * Sets the current total number of results.
   */
  abstract setTotal(value: number): void;
}

// --------------------- Control: Results ---------------------

export abstract class CollectionResultsControl<T> extends CollectionAbstractControl {
  /**
   * Sets the results from the request
   */
  abstract setResults(results: T[]): void;
}

// --------------------- Control: Search ---------------------

export abstract class CollectionSearchControl extends CollectionAbstractControl {
  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   *
   * When implementing the `registerOnChange` method in your own search control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnChange(fn: any): void;
  /**
   * Sets the search value that needs to be shown by the element
   */
  abstract setSearch(value: string): void;
}

// --------------------- Control: Sort ---------------------

export abstract class CollectionSortControl extends CollectionAbstractControl {
  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   *
   * When implementing the `registerOnChange` method in your own sorting control,
   * save the given function so your class calls it at the appropriate time.
   */
  abstract registerOnChange(fn: any): void;
  /**
   * Sets the sort value that needs to be shown by the element
   */
  abstract setSort(sort: string): void;
}

// --------------------- Collection Component ---------------------

@Component({
  selector: 'app-collection',
  template: '<ng-content></ng-content>'
})
export class CollectionComponent<T> implements OnInit, OnDestroy, AfterContentInit {

  @Input() collection: CollectionHandler<T>;
  @Input() searchControl: CollectionSearchControl;

  @Output('request') requestEvent: EventEmitter<void> = new EventEmitter();
  @Output('response') responseEvent: EventEmitter<void> = new EventEmitter();
  @Output('errors') errorsEvent: EventEmitter<any> = new EventEmitter();

  @ContentChildren(CollectionFiltersControl, { descendants: true }) filterControls: QueryList<CollectionFiltersControl>;
  @ContentChildren(CollectionSortControl, { descendants: true }) sortControls: QueryList<CollectionSortControl>;
  @ContentChild(CollectionPaginatorControl) paginationControl: CollectionPaginatorControl;
  @ContentChild(CollectionResultsControl) resultsControl: CollectionResultsControl<T>;
  @ContentChild(CollectionSearchControl) _searchControl: CollectionSearchControl;

  // --- Property: filters ---

  private _filters: CollectionFilter[] = [];
  @Input() set filters(value: CollectionFilter[]) {
    this._filters = value;
    this.collection.setFilter(this.mapFiltersToString(this._filters));
  }
  get filters(): CollectionFilter[] {
    return this._filters;
  }

  // --- Property: search ---

  @Input() set search(value: string) {
    this.collection.setSearch(value);
  }
  get search(): string {
    return this.collection.getSearch();
  }

  // --- Property: page ---

  @Input() set page(value: number) {
    this.collection.setPage(value);
  }
  get page(): number {
    return this.collection.getPage();
  }

  // --- Property: limit ---

  @Input() set limit(value: number) {
    this.collection.setLimit(value);
  }
  get limit(): number {
    return this.collection.getLimit();
  }

  // --- Property: sort ---

  @Input() set sort(value: string) {
    this.collection.setSort(value);
  }
  get sort(): string {
    return this.collection.getSort();
  }

  // --- Property: results ---

  get results(): T[] {
    return this.collection.getResults();
  }

  // --- Property: total ---

  get total(): number {
    return this.collection.getTotal();
  }

  // Inner properties

  private initialPage: number;
  private initialLimit: number;

  // === Lifecycle ===

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.initialPage = this.page;
    this.initialLimit = this.limit;
  }

  ngAfterContentInit(): void {
    if (!this.searchControl) {
      this.searchControl = this._searchControl;
    }

    this.route.queryParams.subscribe(() => {
      this.loadValuesFromUrl();
      this.request();
    });

    this.subscribeForControlChanges();
  }

  ngOnDestroy(): void { }

  // === Functions to interact with the component ===

  request() {
    this.requestEvent.emit();
    this.setLoadingState(true);

    this.collection.request()
      .catch((e) => this.errorsEvent.emit(e))
      .then(() => {
        if (this.paginationControl) { 
          this.paginationControl.setTotal(this.total);
          this.paginationControl.setLimit(this.limit);
        }
        if (this.resultsControl) {
          this.resultsControl.setResults(this.results);
        }

        this.setLoadingState(false);
        this.responseEvent.emit();
      });
  }

  setPage(page: number) {
    this.page = page;
    if (this.paginationControl) { this.paginationControl.setPage(page); }
  }

  setLimit(limit: number) {
    this.limit = limit;
    if (this.paginationControl) { this.paginationControl.setLimit(limit); }
  }

  setSearch(search: string) {
    this.search = search;
    if (this.searchControl) { this.searchControl.setSearch(search); }
  }

  setSort(sort: string) {
    this.sort = sort;
    this.sortControls.forEach(control => control.setSort(sort));
  }

  setFilters(filters: CollectionFilter[]) {
    let queryFilters = filters.filter(filter => !!filter.query);
    const valueFilters = filters.filter(filter => !!filter.value);

    this.filterControls.forEach(control => {
      control.setValues(valueFilters);
      queryFilters = queryFilters.concat(control.getFilters());
    });

    this.filters = queryFilters;
  }

  // === Internal functions to work with url ===

  private loadValuesFromUrl() {
    this.loadPaginationFromUrl();
    this.loadFiltersFromUrl();
    this.loadSortFromUrl();
    this.loadSearchFromUrl();
  }

  private loadPaginationFromUrl() {
    const queryParams = this.route.snapshot.queryParamMap;

    if (queryParams.has('page')) {
      this.setPage(parseInt(queryParams.get('page'), 10));
    } else {
      this.setPage(this.initialPage);
    }
    if (queryParams.has('limit')) {
      this.setLimit(parseInt(queryParams.get('limit'), 10));
    } else {
      this.setLimit(this.initialLimit);
    }
  }

  private loadFiltersFromUrl() {
    const filters: CollectionFilter[] = [];

    this.route.snapshot.queryParamMap.keys.forEach(key => {
      if (key.startsWith('f_')) {
        filters.push({
          name: key.replace('f_', ''),
          value: this.route.snapshot.queryParamMap.get(key)
        });
      }
    });

    this.setFilters(filters);
  }

  private loadSortFromUrl() {
    this.setSort(this.route.snapshot.queryParamMap.get('sort'));
  }

  private loadSearchFromUrl() {
    this.setSearch(this.route.snapshot.queryParamMap.get('search'));
  }

  // === Internal functions to handle controls ===

  setLoadingState(value: boolean) {
    this.filterControls.forEach((filterControl) => filterControl.setLoadingState(value));
    this.sortControls.forEach(control => control.setLoadingState(value));

    if (this.paginationControl) {
      this.paginationControl.setLoadingState(value);
    }
    if (this.resultsControl) {
      this.resultsControl.setLoadingState(value);
    }
    if (this.searchControl) {
      this.searchControl.setLoadingState(value);
    }
  }

  private subscribeForControlChanges() {
    this.filterControls.forEach((filterControl) => {
      filterControl.registerOnAdd(this.onFiltersAdd.bind(this));
      filterControl.registerOnRemove(this.onFiltersRemove.bind(this));
      filterControl.registerOnChange(this.onFiltersChange.bind(this));
    });

    this.sortControls.forEach(control => control.registerOnChange(this.onSortChange.bind(this)));

    if (this.paginationControl) {
      this.paginationControl.registerOnChange(this.onPaginationChange.bind(this));
    }
    if (this.searchControl) {
      this.searchControl.registerOnChange(this.onSearchChange.bind(this));
    }
  }

  private onPaginationChange(pagination: { page: number, limit: number }) {
    this.router.navigate([], { queryParams: { page: pagination.page, limit: pagination.limit }, queryParamsHandling: 'merge' });
  }

  private onSearchChange(search: string) {
    this.router.navigate([], { queryParams: { search, page: 1, limit: this.limit, sort: undefined } });
  }

  private onSortChange(sort: string) {
    this.router.navigate([], { queryParams: { sort }, queryParamsHandling: 'merge' });
  }

  private onFiltersAdd(addedFilters: CollectionFilter[]) {
    const queryParams = { page: 1 };

    addedFilters.forEach((filter: CollectionFilter) => {
      queryParams[`f_${filter.name}`] = filter.value;
    });

    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  private onFiltersRemove(removedFilters: CollectionFilter[]) {
    const queryParams = { page: 1 };

    removedFilters.forEach((filter: CollectionFilter) => {
      queryParams[`f_${filter.name}`] = undefined;
    });

    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  private onFiltersChange(changedFilters: CollectionFilter[]) {
    const queryParams = { page: 1 };

    changedFilters.forEach((filter: CollectionFilter) => {
      queryParams[`f_${filter.name}`] = filter.value;
    });

    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  private mapFiltersToString(filters: CollectionFilter[]): string {
    return filters.map((filter: CollectionFilter) => filter.query).join(' and ');
  }
}
