import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { style, animate, transition, trigger } from '@angular/animations';

import { NavItem } from '../components/sidebar/nav-item.interface';
import { LayoutService, LayoutSettings, MainMenuChangeEvent, NotificationsPanel, TimelinePanel } from '../layout.service';

@Component({
  selector: 'app-erp-layout',
  templateUrl: './erp.component.html',
  styleUrls: ['./erp.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({opacity: 0 }))
      ])
    ])
  ]
})
export class ErpLayouComponent implements OnInit {


  navItems: NavItem[];
  appsList = {
    active: [
      // {
      //   name: 'Ticketing System',
      //   icon: 'taskManager',
      //   url: 'https://ticketing.phillipscollection.com/'
      // }
    ],
    future: []
  };

  isMenuCollapsed: boolean;

  timelinePanel: TimelinePanel;
  notificationsPanel: NotificationsPanel;

  constructor(private layoutService: LayoutService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }

  ngOnInit() {

    this.navItems = [
      {
        text: 'Dashboard',
        icon: 'dashboard',
        link: ['/dashboard'],
        permissions: []
      },
      {
        text: 'Customers',
        icon: 'people_alt',
        link: ['/customers'],
        permissions: ['customers.methods.read'],
        children: [
          {
            text: 'Customers',
            link: ['/customers/dashboard'],
            permissions: ['customers.methods.read'],
          },
          // {
          //   text: 'Statements',
          //   link: ['/customers/statements'],
          //   permissions: ['customers.properties.stats.amtbaldue.read']
          // },
          // {
          //   text: 'Aging',
          //   link: ['/customers/aging'],
          //   permissions: ['invoices.methods.read'],
          // },
          {
            text: 'Exemptions',
            link: ['/exemptions/list'],
            permissions: ['exemptions.methods.read']
          },
          {
            text: 'Reports',
            link: ['/customers/reports', { m: 'customers' }],
            permissions: ['reports.methods.read']
          }
        ]
      },
      {
        text: 'Leads',
        icon: 'person',
        link: ['/leads'],
        permissions: ['leads.methods.read'],
        children: [
          {
            text: 'Leads',
            link: ['/leads'],
            permissions: ['leads.methods.read'],
          }
        ]
      },
      {
        text: 'Sales',
        icon: 'shopping_cart',
        link: ['/sales'],
        permissions: ['orders.methods.read'],
        children: [
          {
            text: 'Orders & Quotes',
            link: ['/sales'],
            permissions: ['orders.methods.read'],
          },
          {
            text: 'Projects',
            link: ['/sales/projects'],
            permissions: ['projects.methods.read'],
          },
          {
            text: 'Reports',
            link: ['/sales/reports', { m: 'sales' }],
            permissions: ['projects.methods.read']
          }
        ]
      },
      {
        text: 'Receivables',
        icon: 'receipt',
        link: ['/receivables/invoices-dashboard'],
        permissions: ['invoices.methods.read'],
        children: [
          {
            text: 'Invoices',
            link: ['/receivables/invoices-dashboard'],
            permissions: ['invoices.methods.read'],
          },
          {
            text: 'Credits',
            link: ['/receivables/credits-dashboard'],
            permissions: ['credits.methods.read']
          },
          {
            text: 'Receipts',
            link: ['/receivables/receipts'],
            permissions: ['receipts.methods.read']
          },
          {
            text: 'Reports', //  reports.methods.read == true
            link: ['/receivables/reports', { m: 'receivables' }],
            permissions: ['projects.methods.read']
          }
        ]
      },
      {
        text: 'Inventory',
        icon: 'category',
        link: ['/inventory'],
        permissions: ['items.methods.read'],
        children: [
          {
            text: 'Items',
            link: ['/inventory/dashboard'],
            permissions: ['items.methods.read'],
          },
          {
            text: 'Transfers',
            link: ['/inventory/transfers'],
            permissions: ['transfers.methods.read']
          },
          {
            text: 'Adjustments',
            link: ['/inventory/adjustments'],
            permissions: ['inventory-adjustments.methods.read']
          },
          {
            text: 'Reports',  //  reports.methods.read == true
            link: ['/inventory/reports', { m: 'inventory' }],
            permissions: ['projects.methods.read']
          }
        ]
      },
      {
        text: 'Vendors',
        icon: 'villa',
        link: ['/vendors'],
        permissions: ['vendors.methods.read'],
        children: [
          {
            text: 'Vendors',
            link: ['/vendors'],
            permissions: ['vendors.methods.read'],
          },
          {
            text: 'Statements',
            link: ['/vendors/statements'],
            permissions: ['vendors.properties.stats.read']
          },
          {
            text: 'Aging',
            link: ['/vendors/aging'],
            permissions: ['bills.methods.read', 'bills.properties.amtbaldue.read']
          },
          {
            text: 'Reports',
            link: ['/vendors/reports', { m: 'vendors' }],
            permissions: ['reports.methods.read']
          }
        ]
      },
      // {
      //   text: 'Purchasing',
      //   icon: 'add_shopping_cart',
      //   link: '/purchasing/requisitions',
      //   children: [
      //     {
      //       text: 'Requisitions',
      //       link: '/purchasing/requisitions'
      //     },
      //     {
      //       text: 'Purchase Orders',
      //       link: '/purchasing/purchase-orders?status=approved',
      //        permissions: ['purchase.methods.read']
      //     },
      //     {
      //       text: 'ETA',
      //       link: '/purchasing/shipments',
      // permissions: ['poshipments.methods.read']
      //     },
      //     {
      //       text: 'Purchase Receipts',
      //       link: '/purchasing/purchase-receipts'
      //     },
      //     {
      //       text: 'Reports',
      //       link: ['/purchasing/reports', { m: 'purchasing' }],
        // permissions: ['reports.methods.read']
      //     }
      //   ]
      // },
      {
        text: 'Payables',
        icon: 'monetization_on',
        link: ['/payables/bills'],
        permissions: ['bills.methods.read'],
        children: [
          {
            text: 'Bills',
            link: ['/payables/bills'],
            permissions: ['bills.methods.read']
          },
          // {
          //   text: 'Credits',
          //   link: '/payables/creditsap',
          // permissions: ['creditsap.methods.read']
          // },
          // {
          //   text: 'Payments',
          //   link: '/payables/payments?status=enabled',
           // permissions: ['payments.methods.read']
          // },
          {
            text: 'Reports',
            link: ['/payables/reports', { m: 'payables' }],
            permissions: ['reports.methods.read']
          }
        ]
      },
      // {
      //   text: 'Warehouse',
      //   icon: 'home',
      //   link: '/warehouse/shipping?status=open',
      //   children: [
      //     {
      //       text: 'Shipping',
      //       link: '/warehouse/shipping?status=open'
        // permissions: ['shippments.methods.read']
      //     },
      //     {
      //       text: 'Reports',
      //       link: ['/warehouse/reports', { m: 'warehouse' }],
        // permissions: ['reports.methods.read']
      //     }
      //   ]
      // },
      // {
      //   text: 'Banking',
      //   icon: 'account_balance',
      //   link: '/bank/banks',
      //   children: [
      //     {
      //       text: 'Banks',
      //       link: '/bank/banks',
        // permissions: ['banks.methods.read']
      //     },
      //     {
      //       text: 'Bank Transfers',
      //       link: '/bank/transfers',
        // permissions: ['bank-transfers.methods.read']
      //     },
      //     {
      //       text: 'Bank Deposits',
      //       link: '/bank/deposits',
        // permissions: ['bank-deposits.methods.read']
      //     },
      //     {
      //       text: 'Checks',
      //       link: '/bank/checks',
        // permissions: ['checks.methods.read']
      //     },
      //     {
      //       text: 'Reports',
      //       link: ['/bank/reports', { m: 'bank' }],
        // permissions: ['reports.methods.read']
      //     }
      //   ]
      // },
      // {
      //   text: 'Accounting',
      //   icon: 'calculate',
      //   link: '/gl/accounts',
      //   children: [
      //     {
      //       text: 'Chart of Accounts',
      //       link: '/gl/accounts',
       // permissions: ['glaccounts.methods.read']
      //     },
      //     {
      //       text: 'Journal',
      //       link: '/gl/journal',
       // permissions: ['gljurnal.methods.read']
      //     },
      //     {
      //       text: 'Trial Balance',
      //       link: '/gl/trial-balance',
       // permissions: ['glaccounts.methods.read']
      //     },
      //     {
      //       text: 'Reports',
      //       link: ['/gl/reports', { m: 'gl' }],
        // permissions: ['reports.methods.read']
      //     }
      //   ]
      // },
      {
        text: 'Users',
        icon: 'admin_panel_settings',
        link: ['/users'],
        permissions: ['users.methods.create'],
        children: [
          {
            text: 'Users',
            link: ['/users'],
            permissions: ['users.methods.create']
          }
        ]
      },
      {
        text: 'Reports',
        icon: 'insights',
        link: ['/reports'],
        permissions: ['reports.methods.read']
      }
    ];
    this.matIconRegistry.addSvgIcon('erpLogo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/erp_logo.svg'));

    this.layoutService.changes.subscribe((changes: Partial<LayoutSettings>) => {
      if ('timelinePanel' in changes) { this.timelinePanel = changes.timelinePanel; }
      if ('notificationsPanel' in changes) { this.notificationsPanel = changes.notificationsPanel; }
    });

    this.layoutService.setMainMenu({ toggle: true });
    this.layoutService.mainMenuChange.subscribe((event: MainMenuChangeEvent) => {
      if (typeof event.collapsed === 'boolean') {
        this.isMenuCollapsed = event.collapsed;
      }
    });
  }
}
