import { Injectable, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { SearchComponent } from './components/search/search.component';

export interface MainMenuChangeEvent {
  collapsed?: boolean;
  toggle?: boolean;
}

export type PageTitle = string | { label: string, link?: any[] | string | null | undefined }[]; 
export interface CreateButton {
  label: string,
  callback: () => void
}

export interface SearchInput {
  placeholder?: string;
  value?: string;
  onChange?: (value: any) => void,
  onSubmit?: (value: any) => void,
  onClear?: () => void
}

export interface TimelinePanel {
  documentId: string;
  open?: boolean;
}

export interface NotificationsPanel {
  open?: boolean;
}

export interface LayoutSettings {
  pageTitle: PageTitle,
  createButton: CreateButton,
  searchInput: SearchInput,
  timelinePanel: TimelinePanel,
  notificationsPanel: NotificationsPanel
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  searchComponent: SearchComponent;

  private settings: LayoutSettings = {
    pageTitle: null,
    createButton: null,
    searchInput: null,
    timelinePanel: null,
    notificationsPanel: null
  };

  /** TODO: refactor next events */

  private changesEmitter: BehaviorSubject<Partial<LayoutSettings>> = new BehaviorSubject(this.settings);
  changes: Observable<Partial<LayoutSettings>> = this.changesEmitter.asObservable();

  private mainMenuChangeEmitter: BehaviorSubject<MainMenuChangeEvent> = new BehaviorSubject({});
  mainMenuChange: Observable<MainMenuChangeEvent> = this.mainMenuChangeEmitter.asObservable();

  public showAvailableAppsListEmitter = new EventEmitter();
  public showUserMenuEmitter = new EventEmitter();

  constructor(private titleService: Title) { }

  getSettings(): LayoutSettings {
    return this.settings;
  }

  setPageDetails(settings: Partial<LayoutSettings>) {
    const defaults: LayoutSettings = {
      pageTitle: null,
      createButton: null,
      searchInput: null,
      timelinePanel: null,
      notificationsPanel: null
    };

    this.settings = Object.assign(defaults, settings);

    if (settings.pageTitle) { this.__setPageTitle(settings.pageTitle); }

    this.changesEmitter.next(this.settings);
  }

  setPageTitle(pageTitle: PageTitle) {
    this.settings.pageTitle = pageTitle;
    this.__setPageTitle(pageTitle);

    this.changesEmitter.next({ pageTitle });
  }

  private __setPageTitle(pageTitle: PageTitle) {
    if (typeof pageTitle === 'string') {
      this.titleService.setTitle(pageTitle);
    } else {
      this.titleService.setTitle(pageTitle.map(el => el.label).join(' / '));
    }
  }

  setCreateButton(createButton: CreateButton) {
    this.settings.createButton = createButton;
    this.changesEmitter.next({ createButton });
  }

  setSearchInput(searchInput: SearchInput) {
    this.settings.searchInput = searchInput;
    this.changesEmitter.next({ searchInput });
  }

  /** Right panels (Timeline, Notifications) */

  openTimelinePanel() {
    this.settings.timelinePanel.open = true;
    this.changesEmitter.next({ timelinePanel: this.settings.timelinePanel });
  }

  closeTimelinePanel() {
    this.settings.timelinePanel.open = false;
    this.changesEmitter.next({ timelinePanel: this.settings.timelinePanel });
  }

  toggleTimelinePanel() {
    this.settings.timelinePanel.open = !this.settings.timelinePanel.open;
    this.changesEmitter.next({ timelinePanel: this.settings.timelinePanel });
  }

  openNotificationsPanel() {
    this.settings.notificationsPanel.open = true;
    this.changesEmitter.next({ notificationsPanel: this.settings.notificationsPanel });
  }

  closeNotificationsPanel() {
    this.settings.notificationsPanel.open = false;
    this.changesEmitter.next({ notificationsPanel: this.settings.notificationsPanel });
  }

  toggleNotificationsPanel() {
    this.settings.notificationsPanel.open = !this.settings.notificationsPanel.open;
    this.changesEmitter.next({ notificationsPanel: this.settings.notificationsPanel });
  }

  closeAllRightPanels() {
    const changes: Partial<LayoutSettings> = {};

    if (this.settings.timelinePanel) { 
      this.settings.timelinePanel.open = false; 
      changes.timelinePanel = this.settings.timelinePanel;
    }
    if (this.settings.notificationsPanel) { 
      this.settings.notificationsPanel.open = false; 
      changes.notificationsPanel = this.settings.notificationsPanel;
    }
    
    this.changesEmitter.next(changes);
  }


  /** TODO: refactor next functions */
  /** Main Menu */

  setMainMenu(settings: MainMenuChangeEvent) {
    this.mainMenuChangeEmitter.next(settings);
  }

  expandMainMenu() {
    this.mainMenuChangeEmitter.next({ collapsed: false });
  }

  collapseMainMenu() {
    this.mainMenuChangeEmitter.next({ collapsed: true });
  }

  disableMainMenuToggle() {
    this.mainMenuChangeEmitter.next({ toggle: false });
  }

  enableMainMenuToggle() {
    this.mainMenuChangeEmitter.next({ toggle: true });
  }



  toggleAvailableAppsList(val) {
    this.showAvailableAppsListEmitter.emit(val);
  }

  toggleUserMenu(val) {
    this.showUserMenuEmitter.emit(val);
  }
}
