import { BaseModel } from 'src/app/core/models/base.model';

export class Account extends BaseModel {
    _id: string;
    email: string;
    name: string;
    phone: string;
    export: boolean;
    alias: string[];
    repcode: string;
    subrepcode: string;
    signature: string;
    boards: string[];
    appData: { [key: string]: any };
    sessions: AccountSession[];
    status: string;
    type: string;
    roles: string[];
}

export interface AccountSession {
    createdAt: number;
    ipAddress: string;
    userAgent: string;
}
