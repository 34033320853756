import { Component, Input } from '@angular/core';
import { FormsHelpersService } from '../../services';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss']
})
export class FormControlErrorComponent {

  @Input() controlFriendlyName: string;
  @Input() control: FormControl;

  constructor(private formsHelpersService: FormsHelpersService) { }

  errorMessages(control: FormControl, controlFriendlyName: string) {
    return this.formsHelpersService
      .getFormControlErrorMessages(control, controlFriendlyName)
      .map((message: string) => `<span> ${message} </span>`);
  }
}
