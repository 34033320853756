import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputPatternOnly]'
})
export class InputPatternOnlyDirective {
  @Input() appInputPatternOnly: string;

  constructor(
    private el: ElementRef
  ) {
  }

  @HostListener('keypress', ['$event']) keyPress(event) {
    const e: KeyboardEvent = event;
    const char = String.fromCharCode(e.keyCode);

    const basicKeys = [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode);
    const pasted = e.keyCode === 86 && e.ctrlKey === true;
    const arrows = e.keyCode >= 35 && e.keyCode <= 39;

    if (basicKeys || pasted || arrows) {
      return;
    }

    const regExp = new RegExp(this.appInputPatternOnly);

    if (!regExp.test(char)) {
      e.preventDefault();
    } else {
      return;
    }
  }
}
