import { BaseModel } from 'src/app/core/models/base.model';

export class SavedSearchModel extends BaseModel {
    module: string;
    name: string;
    params: {
        search: string;
        filters: { [key: string]: (number | string | boolean)[] };
        sort: string;
    };

    getQueryParams() {
        const queryParams = {};

        if (this.params.search) { queryParams[`search`] = this.params.search; }
        if (this.params.sort) { queryParams[`sort`] = this.params.sort; }
        if (Object.keys(this.params.filters).length > 0) {
            Object.keys(this.params.filters).forEach(key => queryParams[`f_${key}`] = this.params.filters[key].join(','));
        }

        return queryParams;
    }
}
