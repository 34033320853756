import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { style, animate, transition, trigger } from '@angular/animations';

import { NavItem } from '../components/sidebar/nav-item.interface';
import { LayoutService, MainMenuChangeEvent } from '../layout.service';

@Component({
  selector: 'app-demo-layout',
  templateUrl: './demo-layout.component.html',
  styleUrls: ['./demo-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DemoLayoutComponent implements OnInit {

  navItems: NavItem[] = [
    {
      text: 'Demo',
      icon: 'people_alt',
      link: ['/demo/cards'],
      children: [
        {
          text: 'Cards',
          link: ['/demo/cards']
        },
        {
          text: 'Forms',
          link: ['/demo/forms']
        },
        {
          text: 'Modals',
          link: ['/demo/modals']
        },
        {
          text: 'Generic tables',
          link: ['/demo/generic-tables']
        },
        {
          text: 'Autocomplete chips',
          link: ['/demo/autocomplete-chips']
        },
        {
          text: 'Tables',
          link: ['/demo/tables']
        },
        {
          text: 'Stepper',
          link: ['/demo/stepper']
        }
      ]
    }
  ];

  appsList = {
    active: [
      {
        name: 'Ticketing System',
        icon: 'taskManager',
        url: 'https://ticketing.phillipscollection.com/'
      }
    ],
    future: []
  };

  isMenuCollapsed: boolean;

  constructor(private layoutService: LayoutService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon('erpLogo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/erp_logo.svg'));

    this.layoutService.mainMenuChange.subscribe((event: MainMenuChangeEvent) => {
      if (typeof event.collapsed === 'boolean') {
        this.isMenuCollapsed = event.collapsed;
      }
    });
  }
}
