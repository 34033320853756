import { Injectable } from '@angular/core';

import { Country } from '../interfaces';
import countries from './countries.data.json';
import countriesCodes from './countries-codes.data.json';
import { CountryPhoneCode } from '../interfaces/country';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  countries: Country[];
  countriesCodes: CountryPhoneCode[];

  constructor() {
    this.countries = countries as Country[];
    this.countriesCodes = countriesCodes as CountryPhoneCode[];
  }

  getCountries(): Country[] {
    return this.countries;
  }

  getCountryByIso2(iso2: string): Country {
    return this.countries.find((country) => iso2.toLowerCase() === country.iso2.toLowerCase());
  }

  getCountryByIso3(iso3: string): Country {
    return this.countries.find((country) => iso3.toLowerCase() === country.iso3.toLowerCase());
  }

  getCountryByIso(iso: string): Country | null {
    if (iso.length === 3) {
      return this.getCountryByIso3(iso);
    }
    if (iso.length === 2) {
      return this.getCountryByIso2(iso);
    }

    return null;
  }

  getCountriesPhoneCodes(): CountryPhoneCode[] {
    return this.countriesCodes;
  }
}
