import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChild
} from '@angular/core';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AbstractControl, ControlValueAccessor, FormControl, FormGroup, NgControl, ValidatorFn} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatOption} from '@angular/material/core';

export interface AutoCompleteChipOption {
  id: string | number;
  label: string;
  childrenCount?: number;
  parent?: number;
}

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss']
})

export class AutocompleteChipsComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  static nextId = 0;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipList') chipList: MatChipList;
  @ContentChildren(MatOption) contentChildren: QueryList<MatOption>;
  @HostBinding() id = `autocomplete-chips-input-${AutocompleteChipsComponent.nextId++}`;

  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedOptions: AutoCompleteChipOption[] = [];
  filteredOptions: Observable<AutoCompleteChipOption[]>;
  formGroup: FormGroup;
  onChangeFunc: (value: any) => void;
  onTouchedFunc: VoidFunction;
  private _ngControl;

  constructor(@Optional() @Self() public ngControl: NgControl, private cdRef: ChangeDetectorRef) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this._ngControl = ngControl;
    }
  }

  @Input() @Optional()
  get formControl() {
    return this._ngControl;
  }

  set formControl(formControl: FormControl) {
    if (formControl) {
      this._ngControl = formControl;
    }
  }

  private _addNewItemEnabled = true;

  @Input()
  get addNewItemEnabled() {
    return this._addNewItemEnabled;
  }

  set addNewItemEnabled(_addNewItemEnabled) {
    this._addNewItemEnabled = _addNewItemEnabled;
  }

  private _placeholder = 'Add new item....';

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(_placeholder) {
    this._placeholder = _placeholder;
  }

  private _value: AutoCompleteChipOption[] = [];

  @Input()
  get value() {
    return this._value;
  }

  set value(_value) {
    this._value = _value;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      chipsList: new FormControl(this.selectedOptions, this.getValidators(this._ngControl.control)),
      inputControl: new FormControl('', [])
    });
    this.formGroup.get('chipsList').statusChanges.subscribe(
      status => {
        this.chipList.errorState = status === 'INVALID';
      }
    );
    this.filteredOptions = this.formGroup.get('inputControl').valueChanges
      .pipe(
        startWith(''),
        map((text: string) => this.filter(text))
      );
  }

  ngAfterViewInit() {
    this.selectedOptions = (this.contentChildren).map((contentChild => {
      return ({
        id: contentChild.id,
        label: contentChild.value
      });
    }));
    this.cdRef.detectChanges();
  }

  writeValue(autocompleteChipsOptions): void {
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChangeFunc = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouchedFunc = fn;
  }

  add(event: MatChipInputEvent): void {
    if (!this.addNewItemEnabled || this.matAutocomplete.isOpen) {
      return;
    }

    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const inputValue = value.trim();
      this.selectedOptions.push({
        id: inputValue,
        label: inputValue
      });
    }

    if (input) {
      input.value = '';
    }

    this.onChangeFunc(this.selectedOptions.map(selectedOption => {
      if (selectedOption.id !== selectedOption.label) {
        return selectedOption;
      } else {
        return selectedOption.label;
      }
    }));
    this.formGroup.get('inputControl').setValue('');
  }

  remove(removedOption: AutoCompleteChipOption): void {
    this.selectedOptions = this.selectedOptions.filter((item: AutoCompleteChipOption) => item.id !== removedOption.id);

    this.onChangeFunc(this.selectedOptions.map(selectedOption => {
      if (selectedOption.id !== selectedOption.label) {
        return selectedOption;
      } else {
        return selectedOption.label;
      }
    }));
    this.formGroup.get('inputControl').setValue('');
    this.formGroup.get('chipsList').setValue(this.selectedOptions);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const found = this._value.find((item: AutoCompleteChipOption) => item.id.toString() === event.option.value.toString());

    if (found) {
      this.selectedOptions.push(found);
      this.input.nativeElement.value = '';

      this.onChangeFunc(this.selectedOptions.map(selectedOption => {
        if (selectedOption.id !== selectedOption.label) {
          return selectedOption;
        } else {
          return selectedOption.label;
        }
      }));
      this.formGroup.get('inputControl').setValue('');
      this.formGroup.get('chipsList').setValue(this.selectedOptions);
    }
  }

  private getValidators(abstractControl: AbstractControl): ValidatorFn {
    if (abstractControl.validator) {
      return abstractControl.validator;
    }
    return null;
  }

  private autocompleteSearchMatch(text: string, term: string) {
    return text
      .toLowerCase()
      .indexOf(term) === 0;
  }

  private filter(text: string): AutoCompleteChipOption[] {
    const inputTextValue = text ? text.toLowerCase() : '';
    return this._value
      .filter((item: AutoCompleteChipOption) => {
        const labelMatched = inputTextValue ? this.autocompleteSearchMatch(item.label, inputTextValue) : true;
        let parentNameIsMatched = false;

        const alreadySelected = this.selectedOptions
          .find((selectionOption: AutoCompleteChipOption) => selectionOption.id === item.id);

        const parent = this._value.find((option: AutoCompleteChipOption) => item.parent && option.id.toString() === item.parent.toString());

        if (parent) {
          parentNameIsMatched = this.autocompleteSearchMatch(parent.label, inputTextValue);
        }

        return (labelMatched || parentNameIsMatched) && !alreadySelected && !item.childrenCount;
      })
      .sort((firstItem, secondItem) => firstItem.label.localeCompare(secondItem.label));
  }
}
