import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss']
})
export class ToolTipComponent implements OnInit {

  @Input()
  text?: string;

  @Input()
  content?: TemplateRef<any>;

  @Input()
  cssClass?: string;

  constructor() { }

  ngOnInit() {
  }
}
