<header id="header" class="bg-white">
	<div class="container px-xl-5 px-lg-3 px-md-3 px-sm-2 px-xs-2">
		<div class="row justify-content-between">
			<div class="col page-title-wrapper text-truncate">
				<h1 class="m-0 align-middle text-accent-950 text-truncate">
					<ng-container *ngFor="let title of pageTitle; let isLast = last">
						<span *ngIf="title.link" [routerLink]="title.link" class="cursor-pointer">{{ title.label }}</span>
						<ng-container *ngIf="!title.link">{{ title.label }}</ng-container>
						<ng-container *ngIf="!isLast"> / </ng-container>
					</ng-container>
				</h1> 
			</div>
			<div class="col-auto align-self-center">
				<div class="float-right">
					<app-search [hidden]="!searchInput" class="align-middle"></app-search>
					<div class="header-actions align-middle">
						<ng-container *ngIf="notificationsPanel">
							<i *ifAllowed="['notifications.methods.read']" class="material-icons-outlined md-icon text-primary-750 align-middle d-inline-block ml-3" 
								[ngClass]="{ 'material-icons-outlined': !notificationsPanel.open, 'material-icons active': notificationsPanel.open }"
								(click)="openNotificationsPanel()">notifications_active</i>
						</ng-container>
						<ng-container *ngIf="timelinePanel">
							<i *ifAllowed="['logs.methods.read']" class="material-icons-outlined md-icon text-primary-750 align-middle d-inline-block ml-3"
								[ngClass]="{ 'material-icons-outlined': !timelinePanel.open, 'material-icons active':timelinePanel.open }" 
								(click)="openTimelinePanel()">schedule</i>
						</ng-container>
						<button *ngIf="createButton" mat-flat-button color="accent" class="ml-3 align-middle create-document" (click)="createButton.callback()">
							<span class="d-flex">
								<i class="material-icons align-self-center">add</i> 
								<span class="align-self-center btn-label">{{ createButton.label }}</span>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>