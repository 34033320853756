<mat-menu #itemsMenu="matMenu"
          (closed)="menuClosed()"
          [overlapTrigger]="false">
  <span *ngFor="let item of items">
    <span *ngIf="item.children && item.children.length">
      <button mat-menu-item
              [matMenuTriggerFor]="childMenu">
        {{item.name}}
      </button>

      <mat-menu #childMenu="matMenu"
                [overlapTrigger]="false">
        <span *ngFor="let child of item.children">
          <button mat-menu-item
                  (click)="itemClick(child)">
            <span>{{child.name}}</span>
          </button>
        </span>
      </mat-menu>
    </span>

    <span *ngIf="item.children && !item.children.length">
      <button mat-menu-item
              (click)="itemClick(item)">
        {{item.name}}
      </button>
    </span>
  </span>
</mat-menu>
