<div class="available-apps-wrapper" (click)="onCancel()">
  <div class="d-flex available-apps" (click)="$event.stopPropagation()">
      <div class="logo-wrapper align-self-strech d-flex" >
          <div class="alt-logo mt-4 mb-4 text-center">
              <mat-icon [svgIcon]="appLogo"  class="align-self-center mx-auto"  color="accent"></mat-icon>
            </div>
            <div class="text-center more-apps mb-35">
              <span class="text-center rounded-circle d-flex all-apps justify-content-center m-auto" (click)="onCancel()"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">account_circle</i></span>
            </div>
      </div>
      <div class="apps-wrapper align-self-strech">
        <div class="row mt-4 mb-3 mx-0 w-100">
          <div class="col-30 title text-grey-A300 font-weight-medium text-uppercase pl-0 align-self-center">Account details</div>
          <div class="col-6 align-self-center">
              <span class="rounded-circle cursor-pointer close-btn d-flex justify-content-center align-items-center" (click)="onCancel()">
                  <i class="material-icons  text-grey-A300">close</i>
              </span>
          </div>
      </div>
        <div class="pt-30">
          <span class="font-weight-bold d-block">{{currentUser.name}}</span>
          <span class="text-primary-A100">{{currentUser.email}}</span><br>
          <span (click)="logOut()" class="text-accent d-block cursor-pointer mt-30">Log Out</span> 
        </div>
      </div>
  </div>
</div>