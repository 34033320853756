<nav mat-tab-nav-bar class="tabs" [color]="selected.color" [disableRipple]="true" [ngClass]="[selected.color ? 'mat-'+selected.color : '']">
    <a mat-tab-link *ngFor="let tab of tabs"
       [active]="selected.name === tab.name"
       [disabled]="tab.disabled || loading"
       [ngClass]="[tab.color ? 'hover-' + tab.color : '']"
       (click)="changeTab(tab)">
      
        <!-- <i *ngIf="tab.icon" [ngClass]="[tab.icon ? tab.icon : '', selected.id === tab.id && tab.color ? 'text-' + tab.color : '']"></i> -->
        <mat-icon [ngClass]="[selected.name === tab.name ? 'text-' + tab.color : '']">{{ tab.matIcon }}</mat-icon>
        {{ tab.label }}
        <mat-chip-list *ngIf="tab.count !== undefined && tab.count !== null">
            <mat-chip>{{ tab.count }}</mat-chip>
        </mat-chip-list>
    </a>
</nav>