<div class="input-dynamic-container" [ngSwitch]="true">
    <mat-form-field *ngSwitchCase="['text', 'number', 'phone', 'email'].includes(config.type)" class="w-100">
        <mat-label>{{ config.label }}</mat-label>
        <input matInput [type]="config.type" [formControl]="formControl" [required]="config.validators?.required" [placeholder]="config.placeholder">
        <app-form-control-error [control]="formControl" [controlFriendlyName]="config.label" matSuffix></app-form-control-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'textarea' === config.type" class="w-100">
        <mat-label>{{ config.label }}</mat-label>
        <textarea matInput [formControl]="formControl" [required]="config.validators?.required" [placeholder]="config.placeholder"></textarea>
        <app-form-control-error [control]="formControl" [controlFriendlyName]="config.label" matSuffix></app-form-control-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'date' === config.type" class="w-100" [ngClass]="{ 'ng-invalid mat-form-field-invalid': formControl.invalid && formControl.touched }">
        <mat-label>{{ config.label }}</mat-label>
        <input matInput [matDatepicker]="datePicker" [value]="formControl.value | map:mapDatePickerValue" [placeholder]="config.placeholder" [required]="config.validators?.required"
                        (dateChange)="onDateChanged($event.value); formControl.markAsDirty()" (focus)="datePicker.open()" (focusout)="formControl.markAsTouched()">
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <app-form-control-error [control]="formControl" [controlFriendlyName]="config.label" matSuffix></app-form-control-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'select' === config.type && !config.multilevel" class="w-100">
        <mat-label>{{ config.label }}</mat-label>
        <mat-select [formControl]="formControl" [placeholder]="config.placeholder" [multiple]="config.multiselect" [required]="config.validators?.required">
            <mat-option *ngIf="!config.validators?.required">-- None ---</mat-option>
            <mat-option *ngFor="let option of config.options | sortBy:'asc':'label'" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
        <app-form-control-error [control]="formControl" [controlFriendlyName]="config.label" matSuffix></app-form-control-error>
    </mat-form-field>

    <ng-container *ngSwitchCase="'toggle' === config.type">
        <mat-slide-toggle [formControl]="formControl" [required]="config.validators?.required">
            {{ config.label }}
        </mat-slide-toggle>
    </ng-container>

    <mat-form-field *ngSwitchCase="'search' === config.type" class="w-100">
        <mat-label>{{ config.label }}</mat-label>
        <input matInput type="text" [formControl]="formControl" [required]="config.validators?.required" [placeholder]="config.placeholder" (keyup.enter)="onSearchSubmit()" (keydown)="resetSelectedResult()">
        <app-form-control-error *ngIf="!searching" [control]="formControl" [controlFriendlyName]="config.label" matSuffix></app-form-control-error>
        <mat-spinner *ngIf="searching" color="accent" diameter="25" class="centered" matSuffix></mat-spinner>
        <mat-icon *ngIf="!searching" color="primary" matSuffix>search</mat-icon>
    </mat-form-field>
</div>

<ng-template #searchResultsTpl>
    <table mat-table [dataSource]="searchResults">
        <ng-container *ngFor="let cel of config.search.row.cels" [matColumnDef]="cel.property">
            <th mat-header-cell *matHeaderCellDef  class="font-weight-normal pl-4">{{ cel.header }}</th>
            <td mat-cell *matCellDef="let result" class="pl-4">{{ result | map:extractProperty:cel.property | viewAs:cel.viewAs }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="config.search.row.cels | map:mapColNames"></tr>
        <tr mat-row *matRowDef="let result; columns: config.search.row.cels | map:mapColNames;" (click)="selectResult(result)" class="cursor-pointer"></tr>
      </table>
</ng-template>