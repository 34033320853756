import { Pipe, PipeTransform } from '@angular/core';

interface Option {
  label: string;
  value: string;
}

@Pipe({
  name: 'optionsFilter'
})
export class OptionsFilter implements PipeTransform {

  transform(options: Option[] = [], term: string): Option[] {
    if (!term.length) {
      return options;
    }

    return options
      .filter((option: Option) => option.label.toLowerCase().indexOf(term.toLowerCase()) !== -1);
  }

}
