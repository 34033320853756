export const environment = {
  production: true,

  apiKey: '3n3Rb0IB8TLLySweT7ewcWrHkGQgbqg8',
  apiUrl: 'https://step-up-production.appspot.com/v1/',
  // Events server url used to listen for db changes
  eventsServer: 'https://nodejs-001.step-up.cloud/listen/',
  // Images url used to get images for all products
  imageUrl: 'https://step-up-production.appspot.com/images/items/'
};
