import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapIdsToValues'
})
export class MapIdsToValuePipe implements PipeTransform {
  transform(value: number[], map: { [key: number]: string }): string {
    const list = value || [];
    return list
      .map((id: number) => map[id])
      .join(', ');
  }
}
