<mat-form-field [formGroup]="formGroup" class="example-chip-list w-100">
  <mat-chip-list #chipList
                 aria-label="Items">
    <mat-chip *ngFor="let value of selectedOptions"
              class="default"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(value)">
      {{value.label}}
      <mat-icon matChipRemove
                *ngIf="removable">cancel
      </mat-icon>
    </mat-chip>
    <input [placeholder]="placeholder"
           #input
           formControlName="inputControl"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
    <app-form-control-error [control]="formGroup"
                            controlFriendlyName="Chips"
                            matSuffix>
    </app-form-control-error>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option.id">
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
