import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CreateButton, LayoutService, LayoutSettings, NotificationsPanel, PageTitle, SearchInput, TimelinePanel } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  pageTitle: PageTitle;
  createButton: CreateButton;
  searchInput: SearchInput;
  timelinePanel: TimelinePanel;
  notificationsPanel: NotificationsPanel;

  private changesSubscription: Subscription;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.changesSubscription = this.layoutService.changes.subscribe((changes: Partial<LayoutSettings>) => {
      if ('pageTitle' in changes) { this.pageTitle = typeof changes.pageTitle === 'string' ? [{ label: changes.pageTitle }] : changes.pageTitle; }
      if ('createButton' in changes) { this.createButton = changes.createButton; }
      if ('searchInput' in changes) { this.searchInput = changes.searchInput; }
      if ('timelinePanel' in changes) { this.timelinePanel = changes.timelinePanel; }
      if ('notificationsPanel' in changes) { this.notificationsPanel = changes.notificationsPanel; }
    });
  }

  ngOnDestroy() {
    this.changesSubscription.unsubscribe();
  }

  openTimelinePanel() {
    this.layoutService.closeAllRightPanels();
    this.layoutService.openTimelinePanel();
  }

  openNotificationsPanel() {
    this.layoutService.closeAllRightPanels();
    this.layoutService.openNotificationsPanel();
  }
}
