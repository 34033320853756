import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Country, SmartyStreetsCheckResult } from 'src/app/shared/interfaces';
import { SmartyStreetsService } from 'src/app/shared/services';
import { SharedValidators } from '../../shared.validators';

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string;
  fax?: string;
  email: string;
  web: string;
  rdi: string;
  notes: string;
  isValid: string;
}

enum ADDRESS_VALID_TYPES {
  INVALID = 'invalid',
  API_VALIDATED = 'api_validated',
  USER_VALIDATED = 'user_validated'
}

@Component({
  selector: 'app-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss']
})
export class InputAddressComponent implements OnInit, ControlValueAccessor {

  @Input() countries: Country[];

  @Input() set value(value: Address) {
    this.innerForm.patchValue(value);
    this.addressChecked = false;
  }
  get value() {
    return this.innerForm.value;
  }

  disabled: boolean;
  innerForm: FormGroup;
  selectedCountry: Country;
  suggestions: SmartyStreetsCheckResult[] = [];
  addressChecked = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private fb: FormBuilder, private smartyStreetService: SmartyStreetsService, 
              private snackBar: MatSnackBar, private sharedValidators: SharedValidators) {
    this.innerForm = this.fb.group({
      country: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      zip: ['', Validators.required],
      rdi: [''],
      notes: [''],
      isValid: [''],
      phone: ['', this.sharedValidators.validatePhoneNumber()],
      fax: ['', this.sharedValidators.validatePhoneNumber()],
      email: ['', Validators.email],
      web: ['', Validators.pattern('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})')]
    });

    this.innerForm.get('country').valueChanges.subscribe(this.updateStateFieldByCountry.bind(this));

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  writeValue(value: any) {
    this.innerForm.patchValue(value);
    this.addressChecked = false;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // Inner functions

  clearStateInput() {
    this.innerForm.get('state').setValue('');
    this.innerForm.get('state').markAsUntouched();
    this.innerForm.get('state').updateValueAndValidity();
  }

  updateStateFieldByCountry(countryCodeIso3: string) {
    this.selectedCountry = this.countries.find(c => countryCodeIso3 === c.iso3);
    if (this.selectedCountry?.states?.length > 0) {
      this.innerForm.get('state').setValidators([Validators.required]);
    } else {
      this.innerForm.get('state').setValidators([]);
    }
    this.innerForm.get('state').updateValueAndValidity();
  }

  onAddressChanged() {
    this.suggestions = [];
    this.addressChecked = false;

    this.innerForm.get('rdi').setValue('');
    if (this.innerForm.get('country').value === 'USA') {
      this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.INVALID);
    } else {
      this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.USER_VALIDATED);
    }
  }

  verifyAddress() {
    this.smartyStreetService.checkUSAAddress(
      this.innerForm.get('city').value,
      this.innerForm.get('state').value,
      this.innerForm.get('zip').value,
      this.innerForm.get('address').value)
    .toPromise().then((smartyResponse: SmartyStreetsCheckResult[]) => {
      this.suggestions = smartyResponse;
      const firstMatch = this.suggestions && this.suggestions[0];

      const exactMatchFound = firstMatch
        && firstMatch.city === this.innerForm.get('city').value
        && firstMatch.address === this.innerForm.get('address').value
        && this.innerForm.get('zip').value.indexOf(firstMatch.zip)
        && firstMatch.state === this.innerForm.get('state').value;

      if (exactMatchFound) {
        if (firstMatch.plus4code) {
          this.innerForm.get('zip').setValue(`${firstMatch.zip}-${firstMatch.plus4code}`);
        }
        this.innerForm.get('rdi').setValue(firstMatch.rdi || '');
        this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.API_VALIDATED);
      } else {
        this.addressChecked = true;
      }
    }).catch((e: any) => {
      this.snackBar.open(e.error.message, null, { duration: 7000, panelClass: ['bg-danger', 'text-white'] });
    });
  }

  onUserValidationChange({ checked }: MatCheckboxChange): void {
    this.innerForm.get('rdi').setValue('');
    if (checked) {
      this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.USER_VALIDATED);
    } else {
      this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.INVALID);
    }
  }

  useSuggestion(smartySuggestion: SmartyStreetsCheckResult) {
    this.innerForm.get('address').setValue(smartySuggestion.address);
    this.innerForm.get('city').setValue(smartySuggestion.city);
    this.innerForm.get('zip').setValue(`${smartySuggestion.zip}-${smartySuggestion.plus4code}`);
    this.innerForm.get('state').setValue(smartySuggestion.state);
    this.innerForm.get('rdi').setValue(smartySuggestion.rdi);
    this.innerForm.get('isValid').setValue(ADDRESS_VALID_TYPES.API_VALIDATED);

    this.suggestions = [];
    this.addressChecked = false;
  }
}
